import React, { useEffect, useRef, useState } from 'react'
import { postData } from '../../Hooks/api'
import { Toast } from 'primereact/toast'
import { useOutletContext } from 'react-router-dom'
import ClassForm from './ClassForm'
import SchedulePreference from './SchedulePreference'
import { getDays } from '../../Constant/general'

const Preference = () => {
  const {teacher} = useOutletContext();
  const [ idSchedule, setIdSchedule ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const toast = useRef(null);
  const [ preference, setPreference ] = useState({
    jenisKelas: [],
    rentangUsia: [],
    subject: [],
    jadwal: []
  });

  const getPreference = async () => {
    setLoading(true);

    postData(`available-schedule`, {guru: teacher._id})
    .then((response) => {
        const jenisKelas = [] 
        const scheduleSort = response.data.jadwal.sort((a,b) => {return getDays('ID').findIndex(day => day === a.hari) - getDays('ID').findIndex(day => day === b.hari)});
 
        response.data.jenisKelas.forEach(classType => {        
            jenisKelas.push(classType.charAt(0).toUpperCase() + classType.slice(1));
        });
               
        setPreference({
            jenisKelas: jenisKelas,
            jadwal: scheduleSort,
            rentangUsia: response.data.usia,
            subject: response.data.subjek
        });

        setIdSchedule(response.data._id);
        setLoading(false);
    })
    .catch((error) => {
      if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
        toast.current.show({
          severity: 'error',
          summary: 'Load Failed!',
          detail: error.response.data.message,
          life: 3000
        });
      }
        
      setLoading(false);
    })
  }

  useEffect(() => {
    if (teacher._id) getPreference();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teacher]);

  return (
    <div className='flex flex-column gap-3'>
      <Toast ref={toast}/>
      <ClassForm 
        toast={toast} 
        preference={preference} 
        refreshAction={() => getPreference()} 
        idSchedule={idSchedule}
      />
      <SchedulePreference 
        preference={preference} 
        loading={loading} 
        setLoading={setLoading}
        toast={toast} 
        idSchedule={idSchedule} 
        refreshAction={() => getPreference()} 
      />
    </div>
  )
}

export default Preference
