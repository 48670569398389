import React, { useRef } from 'react';
import FormLogin from './FormLogin';
import { Toast } from 'primereact/toast';

const Login = () => {
  const toast = useRef(null);
  return (
    <div>
      <Toast ref={toast}/>
      <div className='flex lg:flex-row flex-column align-items-center pt-5 lg:h-screen gap-2'>
        <div className='flex lg:flex-order-0 flex-order-1 justify-content-center lg:w-5 md:w-12 sm:w-12 w-12 lg:mb-0 mb-5'>
          <FormLogin toast={toast} />
        </div>
        <div className='lg:w-7 flex lg:flex-order-1 flex-order-0 flex-column align-items-center gap-1 text-center lg:mt-0 lg:pt-0 mt-5 pt-5'>
          <h1 className='m-0 p-0'>Edufic Teacher Portal</h1>
          <img src='../img/teacher-2.png' alt='Edufic Teacher' height={320}/>
          <p className='lg:text-lg md:text-lg lg:w-10 md:w-10 sm:w-10 w-11'>
            Edufic Teacher Portal (ETP) adalah sebuah platform yang digunakan oleh para guru Edufic untuk menunjang proses pembelajaran seperti presensi, 
            manajemen kelas, sekaligus sarana monitoring dan controling murid. Dengan adanya platform ETP, para guru Edufic diharapkan dapat 
            terbantu dalam berbagai aktivitas mengajar.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Login
