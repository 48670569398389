import { Dialog } from 'primereact/dialog'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getData } from '../../Hooks/api'
import { Button } from 'primereact/button'

const Shortlink = () => {
    const { shortlink } = useParams()
    const [ dialogError, setDialogError ] = useState(false)
    const [ errorMessage, setErrorMessage ] = useState('') 

    const getShortLink = async () => {
        getData(`short-link/${shortlink}`)
        .then((response) => {
            window.location.replace(response.data);
        })
        .catch((error) => {
            setErrorMessage(error.response.data.message)
            setDialogError(true)
        })
    }
  
    useEffect(() => {
      getShortLink();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    const hideDialogError = () => {
      setDialogError(false)
      setErrorMessage('')
      window.location.replace("https://edufic.id")
    }
  
    const footerDialogError = (
      <div className='text-center mt-0'>
        <Button label="Ok" onClick={() => hideDialogError()} autoFocus />
      </div>
    )
    
    return (
      <div className='text-center mb-0'>
          <Dialog visible={dialogError} header='Link tidak ditemukan' footer={footerDialogError} 
            onHide={() => hideDialogError()} style={{width:'30rem', margin: '15px'}}>
              <div className='text-center mb-0'>
                  {/* <i className="pi pi-exclamation-triangle mb-3" style={{ fontSize: '6rem', color: 'red' }} /> */}
                  <img src='/img/url-not-found.png' style={{width: '15rem'}} alt='URL Not Found'/>
                  <p style={{ fontSize: '18px'}}>{errorMessage}</p>
              </div>
          </Dialog>
      </div>
    )
}

export default Shortlink
