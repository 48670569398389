import './App.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/lara-light-green/theme.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import {PrimeReactProvider} from 'primereact/api';
import { ProtectedPages, PublicPages } from './Hooks/auth';
import NotFound from './Pages/NotFound/NotFound';
import AuthLayouts from './Layouts/PublicLayouts/AuthLayouts';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Help from './Pages/Help';
import Dashboard from './Pages/Dashboard';
import MainLayouts from './Layouts/PrivateLayouts/MainLayouts';
import Revenue from './Pages/Revenue';
import Preference from './Pages/Preference';
import ClassManagement from './Pages/ClassManagement';
import DetailClass from './Pages/DetailClass';
import CheckRoom from './Pages/DetailClass/Overview/CheckRoom';
import RevenueReport from './Pages/Revenue/RevenueReport';
import Profile from './Pages/Profile';
import MySchedule from './Pages/Calendar';
import Recording from './Pages/Recording';
import Shortlink from './Pages/Shortlink';

function App() {
  return (
    <PrimeReactProvider>
      <Router>
        <Routes>
          <Route element={<ProtectedPages />} >
            <Route path='/' element={<MainLayouts />} >
              <Route index element={<Dashboard />} />
              <Route path='/class-management' element={<ClassManagement />} />
              <Route path='/class-management/:id' element={<DetailClass />} />
              <Route path='/revenue' element={<Revenue />} />
              <Route path='/preference' element={<Preference />} />
              <Route path='/profile' element={<Profile />}/>
              <Route path='/my-schedule' element={<MySchedule />} />
            </Route>
          </Route>
          <Route element={<PublicPages />}>
            <Route path='/auth' element={<AuthLayouts />}>
              <Route path='/auth/login' element={<Login />} />
              <Route path='/auth/register' element={<Register />} />
              <Route path='/auth/help' element={<Help />} />
            </Route>
          </Route>
          <Route path='/recording/:id' element={<Recording />} />
          <Route path='/revenue/report' element={<RevenueReport />}/>
          <Route path='/edufic-room/:id' element={<CheckRoom/>} />
          <Route path='/404' element={<NotFound/>} />
          <Route path='/web/:shortlink' element={<Shortlink />} />
          <Route path='*' element={<Navigate replace to="/404" />} />
        </Routes>
      </Router>
    </PrimeReactProvider>
  );
}

export default App;
