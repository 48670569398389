import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { getDays, hours24 } from '../../Constant/general'
import { Button } from 'primereact/button'
import { postData } from '../../Hooks/api'

const ScheduleForm = ({visibility, setVisibility, idSchedule, toast, refreshAction}) => {
  const [ loading, setLoading ] = useState(false);
  const newSchedule = {
    hari: "",
    startTime: {
        hours: '',
        minutes: '',
    },
    endTime: {
        hours: '',
        minutes: ''
    }
  }

  const scheduleValidate = (data) => {
    let errors = {};

    if (!data.hari) errors.hari = "Day is required";
    if (!data.startTime?.hours) errors.startTime = {...errors.startTime?.hours, hours: 'Required'};
    if (!data.startTime?.minutes) errors.startTime = {...errors.startTime?.minutes, minutes: 'Required'};
    if (!data.endTime?.hours) errors.endTime = {...errors.endTime?.hours, hours: 'Required'};
    if (!data.endTime?.minutes) errors.endTime = {...errors.endTime?.minutes, minutes: 'Required'};
    
    return errors;
  }

  const addNewSchedule = (data, form) => {
    setLoading(true);
    
    const dataBody = {
        hari: data.hari,
        jamAwal: new Date().setHours(parseInt(data.startTime.hours), parseInt(data.startTime.minutes)),
        jamAkhir: new Date().setHours(parseInt(data.endTime.hours), parseInt(data.endTime.minutes)),
    }

    postData(`add-available-schedule/${idSchedule}`, dataBody)
    .then(() => {
        refreshAction();
        toast.current.show({
            severity: 'info',
            summary: 'Update Success',
            detail: 'Preference successfully updated, thanks for your attention.',
            life: 3000
        });
        setVisibility();
        form.restart();
        setLoading(false);
    })
    .catch((error) => {
        if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
            toast.current.show({
                severity: 'error',
                summary: 'Update Failed!',
                detail: error.response.data.message,
                life: 3000
            });
        }
        
        setLoading(false);
    });    
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  
  const getFormErrorMessage = (meta) => {
    
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  return (
    <Dialog 
        visible={visibility}
        onHide={setVisibility}
        header="New Schedule"
        style={{ width: '50vw' }} 
        breakpoints={{ '960px': '75vw', '641px': '95vw' }}
    >  
        <Form 
            initialValues={newSchedule}
            validate={scheduleValidate}
            onSubmit={addNewSchedule}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                    <Field
                        name='hari'
                        render={({input, meta}) => (
                            <div className="flex-auto">
                                <label htmlFor="hari" className="font-bold block mb-1">Hari</label>
                                <Dropdown 
                                    id="hari" 
                                    name='hari' 
                                    {...input} 
                                    invalid = {isFormFieldValid(meta)}
                                    options={getDays('ID')}
                                    disabled={loading}
                                    filter
                                    placeholder='Choose day'
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className="flex-auto">
                        <label htmlFor="startTime.hours" className="font-bold block mb-1">Jam Awal</label>
                        <div className='flex gap-1'>
                            <Field
                                name='startTime.hours'
                                render={({input, meta}) => (
                                    <div className='w-full'>
                                        <Dropdown 
                                            id="startTime.hours" 
                                            name='startTime.hours' 
                                            {...input} 
                                            invalid = {isFormFieldValid(meta)}
                                            options={hours24}
                                            disabled={loading}
                                            placeholder='hours'
                                            filter
                                        />
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )}
                            />
                            <span className='font-bold text-3xl'>:</span>
                            <Field
                                name='startTime.minutes'
                                render={({input, meta}) => (
                                    <div className='w-full'>
                                        <Dropdown 
                                            id="startTime.minutes" 
                                            name='startTime.minutes' 
                                            {...input} 
                                            invalid = {isFormFieldValid(meta)}
                                            options={['00', '30']}
                                            disabled={loading}
                                            placeholder='minutes'
                                        />
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                    <div className="flex-auto">
                        <label htmlFor="endTime.hours" className="font-bold block mb-1">Jam Akhir</label>
                        <div className='flex gap-1'>
                            <Field
                                name='endTime.hours'
                                render={({input, meta}) => (
                                    <div className='w-full'>
                                        <Dropdown 
                                            id="endTime.hours" 
                                            name='endTime.hours' 
                                            {...input} 
                                            invalid = {isFormFieldValid(meta)}
                                            options={hours24}
                                            disabled={loading}
                                            placeholder='hours'
                                            filter
                                        />
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )}
                            />
                            <span className='font-bold text-2xl'>:</span>
                            <Field
                                name='endTime.minutes'
                                render={({input, meta}) => (
                                    <div className='w-full'>
                                        <Dropdown 
                                            id="endTime.minutes" 
                                            name='endTime.minutes' 
                                            {...input} 
                                            invalid = {isFormFieldValid(meta)}
                                            options={['00', '30']}
                                            disabled={loading}
                                            placeholder='minutes'
                                        />
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                    <div className='flex justify-content-center gap-2 mt-2'>
                        <Button label='Cancel' type='button' severity='danger' className='w-max' outlined  onClick={() => setVisibility()} loading={loading}/>
                        <Button label='Add' type='submit' severity='primary' className='w-max' autoFocus loading={loading}/>
                    </div>
                </form>
            )}
        />

    </Dialog>
  )
}

export default ScheduleForm
