import { Chart } from 'primereact/chart'
import React, { useEffect, useState } from 'react'
import { List_CHART_COLOR } from '../../Constant/general';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const EduChart = ({ 
  chartTitle, 
  chartType = "pie", 
  data, 
  legendPosition="bottom",
  height = "280px",
  labelFormatter = () => {},
}) => {
  const documentStyle = getComputedStyle(document.documentElement);
  const [ dataChart, setDataChart ] = useState({
    labels: [],
    datasets: []
  });

  const chartOptions = {
    cutout: chartType === "pie" ? '0%' : '40%',
    plugins: {
        legend: {
            labels: {
                usePointStyle: true
            },
            position: legendPosition
        },
        title: {
            display: true,
            text: chartTitle,
            font: {
            size: 18,
            weight: 'bold',
            color: 'black'
            }
        },
        datalabels: {
            color: "#FFFFFF",
            font: {
              size: '14px'
            },
            anchor: 'end',
            align: 'start',
            formatter: labelFormatter,
            display: function(context) {
                // Hide the label if the value is 0    
                return context.dataset.data[context.dataIndex] !== 0;
            },
        }
    },
    responsive: true,
    maintainAspectRatio: false
  }

  useEffect(() => {
    if (data) {
        const label = [];
        const datasets = {
            data: [],
            backgroundColor: []
        };

        data.map((detail, index) => {
            label.push(detail.label);
            datasets.data.push(detail.value);
            datasets.backgroundColor.push(documentStyle.getPropertyValue(List_CHART_COLOR[index]))
            
            return detail;
        });

        setDataChart({
            labels: label,
            datasets: [datasets]
        });   
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  
  return (
    <div style={{width: '100%', height: height}}>
      <Chart type={chartType} data={dataChart} options={chartOptions} plugins={[ChartDataLabels]}/>
    </div>
  )
}

export default EduChart
