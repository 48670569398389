import { Card } from 'primereact/card';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { postData } from '../../Hooks/api';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { useNavigate } from 'react-router-dom';

const FormLogin = ({toast}) => {
  const navigate = useNavigate();
  const [ loading, setLoading ] = useState(false);
  const initialDataLogin = {
    email: "",
    password: "" 
  };

  const dataValidateLogin = (data) => {
    let errors = {};

    if (!data.email) { 
      errors.email = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
      errors.email = 'Invalid email address. E.g. example@email.com';
    }

    if (!data.password) errors.password = "Password is required";

    return errors;
  }

  const login = async (data, form) => {
    setLoading(true);

    postData('teacher/login', data)
    .then((response) => {
      localStorage.setItem('accessToken', response.data.token);
      localStorage.setItem('teacherId', response.data.teacher._id);
      setTimeout(() => {
        form.restart();
        setLoading(false);
        navigate('/');
      }, 2000);
    })
    .catch((error) => {
      if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
        toast.current.show({
          severity: 'error',
          summary: 'Login Failed!',
          detail: error.response.data.message,
          life: 3000
        });
      }
      
      setLoading(false);
    })
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  return (
    <Card className='p-fluid lg:w-10 md:w-10 sm:w-8 w-12 border-round-xl'>
      <div className='flex flex-column align-items-center mb-2'>
        <img src='../icons/edufic-icon.png' alt='Icon Edufic' height={100} className='w-max'/>
        <h2 className='m-0 p-0'>Teacher Portal</h2>
      </div>
      <Form
        initialValues={initialDataLogin}
        validate={dataValidateLogin}
        onSubmit={login}
        render={({handleSubmit}) => (
          <form onSubmit={handleSubmit} className='flex flex-column gap-3'>
            <Field
              name='email'
              render={({input, meta}) => (
                <div className="flex-auto">
                  <label htmlFor="email" className="font-bold block mb-1">Email</label>
                  <InputText 
                    id="email" 
                    name='email' 
                    {...input} 
                    invalid = {isFormFieldValid(meta)}
                    disabled={loading}
                  />
                  {getFormErrorMessage(meta)}
                </div>
              )}
            />
            <Field
              name='password'
              render={({input, meta}) => (
                <div className="flex-auto">
                  <label htmlFor="password" className="font-bold block mb-1">Password</label>
                  <Password 
                    id="password" 
                    name='password' 
                    {...input} 
                    invalid = {isFormFieldValid(meta)}
                    disabled={loading}
                    toggleMask
                  />
                  {getFormErrorMessage(meta)}
                </div>
              )}
            />
            <Button label='Login' type='submit' loading={loading} className='w-full'/>
          </form>
        )}
      />
    </Card>
  )
}

export default FormLogin
