import { Button } from 'primereact/button'
import { ConfirmDialog } from 'primereact/confirmdialog'
import React from 'react'

const NegativeConfirmDialog = ({
    visible,
    visibleHandler,
    acceptAction,
    rejectAction,
    titleConfirmation,
    messageConfirmation,
    acceptLabelButton,
    loading
}) => {
  return (
    <ConfirmDialog
        group='declarative'
        visible={visible}
        onHide={visibleHandler}
        accept={acceptAction}
        reject={rejectAction}
        breakpoints={{ '900px': '50vw', '500px': '95vw' }}
        content={() => (
            <div className="flex flex-column align-items-center p-5 surface-overlay border-round">
                <div className="border-circle bg-red-500 text-white inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                    <i className="pi pi-exclamation-triangle text-5xl"></i>
                </div>
                <span className="font-bold text-2xl block mt-2 mb-0">
                    {titleConfirmation}
                </span>
                <p className="m-0 p-0 text-center">
                    {messageConfirmation}
                </p>
                <div className="flex align-items-center gap-2 mt-4">
                    <Button
                        label={acceptLabelButton}
                        onClick={acceptAction}
                        severity='danger'
                        className="w-8rem"
                        size='small'
                        loading={loading}
                    ></Button>
                    <Button
                        label="Cancel"
                        outlined
                        onClick={rejectAction}
                        severity='info'
                        className="w-8rem"
                        size='small'
                        autoFocus
                        loading={loading}
                    ></Button>
                </div>
            </div>
        )}
    >
    </ConfirmDialog>
  )
}

export default NegativeConfirmDialog
