import { Card } from 'primereact/card'
import { DataTable } from 'primereact/datatable'
import React, { useEffect, useRef, useState } from 'react'
import { postData } from '../../Hooks/api';
import { Toast } from 'primereact/toast';
import { FilterMatchMode } from 'primereact/api';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { Link, useOutletContext } from 'react-router-dom';
import { formatIDRCurrency, formatMonth } from '../../Hooks/helper';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { getSeverity } from '../../Constant/general';
import { Button } from 'primereact/button';

const Revenue = () => {
  const toast = useRef(null);
  const {teacher} = useOutletContext();
  const [ loading, setLoading ] = useState(false);
  const [ listRevenue, setListRevenue ] = useState([]);
  const [ formatSesi, setFormatSesi ] = useState([]);

  // Filter Primereact
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const getListRevenue = async () => {
    setLoading(true);

    postData('teacher-salary', { idTeacher: teacher._id })
    .then((response) => {
      const salaryReport = response.data.listGaji;
      
      salaryReport.map((report) => {
        setFormatSesi(modifyDetailSession(report));
        report["bulanGajiString"] = formatMonth(report.bulanGaji);
        return salaryReport;
      });
       
      setListRevenue(salaryReport.filter(salary => salary.statusGaji === "Terbayar"));
      setLoading(false);
    })
    .catch((error) => {
      if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
        toast.current.show({
          severity: 'error',
          summary: 'Load Failed!',
          detail: error.response.data.message,
          life: 3000
        });
      }
      
      setLoading(false);
    });
  };

  const modifyDetailSession = (data) => {
    const listResult = []

    if (data !== null && data.sesi.length > 0) {
        const sesi = data.sesi
        sesi.map((detailData) => {
            const kelas = detailData.kelas
            const subjekKelas = `${kelas.namaKelas} - ${kelas.kodeBatch}.${kelas.kodeKelas} (${kelas.jenisKelas}) - Lvl.${kelas.level}`
            const listSession = []
            const bulanGaji =  formatMonth(data.bulanGaji)
            const totalGaji = data.jumlahGaji

    
            if (detailData.indexSesi.length > 0) {
                const newIndexSesi = detailData.indexSesi.split(",")
                
                newIndexSesi.map((index) => {
                  listSession.push(parseInt(index) + 1);
                  return index;
                })
            }
    
            const formatData = {
                _id: detailData._id,
                subjectClass: subjekKelas,
                qtySession: detailData.jumlahSesi,
                listSession: listSession,
                bulanGaji: bulanGaji,
                nominal: data.jumlahGaji / data.jumlahSesi * detailData.jumlahSesi,
                totalGaji: totalGaji
            }
    
            listResult.push(formatData)
            return detailData;
        }) 
    }

    return listResult
  }

  useEffect(() => {
    if (teacher._id) getListRevenue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teacher._id]);

  const headerTemplate = () => (
    <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column justify-content-between gap-2'>
      <div className='flex lg:justify-content-start md:justify-content-start sm:justify-content-start justify-content-between gap-1 align-items-center edu-text-doff'>
        <h2 className='m-0 p-0'>Revenue History</h2>
      </div>
      <IconField iconPosition="left">
        <InputIcon className="pi pi-search"> </InputIcon>
        <InputText 
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Search" 
          className='w-full' 
        />
      </IconField>
    </div>
  );

  const bodyStatusTemplate = (rowData) => (
    <Tag value={rowData} severity={getSeverity(rowData, "SALARY")}></Tag>
  );

  const actionBodyTemplate = (rowData) => (
    <Link to="/revenue/report" state={{sesi: formatSesi, guru: teacher}}>
      <Button 
        label='Slip' 
        icon='pi pi-file-pdf' 
        className='ml-2' 
        size='small' 
        outlined 
        severity='danger'
      />
    </Link>
  );

  return (
    <Card>
        <Toast ref={toast} />

        <DataTable
          value={listRevenue}
          removableSort
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          filters={filters}
          globalFilterFields={['bulanGaji', 'jumlahGaji', 'jumlahSesi', 'statusGaji', 'bulanGajiString']}
          className='lg:text-sm md:text-sm sm:text-sm text-xs'
          size='small'
          loading={loading}
          header={headerTemplate}
        >
          <Column body={(e, index) => index.rowIndex  + 1} />
          <Column field='bulanGaji' header="Bulan Gaji" sortable body={(e) => formatMonth(e.bulanGaji)}/>
          <Column field='jumlahSesi' header="Qty Sesi" sortable/>
          <Column field='jumlahGaji' header="Nominal Gaji" sortable body={(e) => formatIDRCurrency(e.jumlahGaji)}/>
          <Column field='statusGaji' header="Status" body={(e) => bodyStatusTemplate(e.statusGaji)} />
          <Column body={actionBodyTemplate}/>
        </DataTable>
    </Card>
  )
}

export default Revenue