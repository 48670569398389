import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Panel } from 'primereact/panel'
import React from 'react'
import { copyLink, formattedDate } from '../../Hooks/helper'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Skeleton } from 'primereact/skeleton'

const SessionHistory = ({type="PANEL", detailClass={}, toast=null}) => {
  const items = Array.from({ length: 5 }, (v, i) => i);

  const recordingTemplate = (data) => (
    data.linkYoutube ? <Link to={data.linkYoutube} target='_blank'>
        <Button label="Youtube" icon="pi pi-youtube" iconPos='left' severity='danger' size='small'/>
    </Link> : ""
  );

  const panelHeaderTemplate = () => (
    <div className='flex flex-row gap-2 align-items-center'>
      <h3 className='p-0 m-0'>Session History</h3>
      {/* <Button icon="pi pi-share-alt" label='Share' size='small' outlined className='py-1 border-round-2xl' onClick={shareHandler}/> */}
    </div>
  );

  const shareHandler = () => {
    const text = `*Recording Documentation*\n${window.location.host}/recording/${detailClass._id}`
    copyLink(text, toast);
  }

  return (
    type === "PANEL" ? <Panel header={panelHeaderTemplate} toggleable collapsed={true}>
      <DataTable
          value={detailClass.historyZoom}
          removableSort
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          className='lg:text-sm md:text-sm sm:text-sm text-xs'
      >
          <Column field="linkZoom" header="Nama Room" sortable/>
          <Column field="jadwalKelas" header="Mulai Sesi" body={(e) => formattedDate(e.jadwalKelas)} sortable/>
          <Column field="akhirKelas" header="Berakhir Sesi" sortable body={(e) => formattedDate(e.akhirKelas)}/>
          <Column field="linkYoutube" header="Dokumentasi" body={recordingTemplate}/>
      </DataTable>
    </Panel> : <Card className='m-3'>
      { detailClass._id ? <div>
          <div className='flex flex-column align-items-center'>
            <h3 className='m-0 p-0'>Recording Class</h3>
            <h2 className='m-2 p-0 text-primary'>{detailClass.kodeBatch}.{detailClass.kodeKelas} {detailClass.namaKelas} (Lvl {detailClass.level})</h2>
            <h4 className='mt-0 mb-2 p-0'>Teacher: {detailClass.guru?.nama}</h4>
          </div>
          <DataTable
            value={detailClass.historyZoom}
            removableSort
            paginator
            rows={25}
            rowsPerPageOptions={[5, 10, 25, 50]}
            className='lg:text-sm md:text-sm sm:text-sm text-xs'
          >
            <Column field="linkZoom" header="Nama Room"/>
            <Column field="jadwalKelas" header="Mulai Sesi" body={(e) => formattedDate(e.jadwalKelas)} sortable/>
            <Column field="akhirKelas" header="Berakhir Sesi" sortable body={(e) => formattedDate(e.akhirKelas)}/>
            <Column field="linkYoutube" header="Dokumentasi" body={recordingTemplate}/>
          </DataTable>
        </div> : <div>
          <div className='flex flex-column align-items-center'>
            <h3 className='m-0 p-0'>Recording Class</h3>
            <Skeleton width='10rem' height='1.5rem' className='m-2' />
            <Skeleton width='12rem' height='1rem' className='mt-0 mb-2' />
          </div>
          <DataTable
            value={items}
            removableSort
            paginator
            rows={25}
            rowsPerPageOptions={[5, 10, 25, 50]}
            className='lg:text-sm md:text-sm sm:text-sm text-xs'
          >
            <Column field="linkZoom" header="Nama Room" body={<Skeleton  />} />
            <Column field="jadwalKelas" header="Mulai Sesi"  body={<Skeleton  />} />
            <Column field="akhirKelas" header="Berakhir Sesi" body={<Skeleton  />} />
            <Column field="linkYoutube" header="Dokumentasi" body={<Skeleton  />}/>
          </DataTable>
        </div>
      }
      
    </Card>
  )
}

export default SessionHistory
