import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { putData } from '../../../Hooks/api';

const FormUpdateSchedule = ({visibility, setVisibility, currentSchedule, refreshAction, toast}) => {
  const [ loading, setLoading ] = useState(false);
  const [ initialValues, setInitialValues ] = useState({
    jadwalSesi: '',
    indexSesi: '',
    classId: ''
  });

  useEffect(() => {
    if (currentSchedule) setInitialValues(currentSchedule);
  }, [currentSchedule])

  const validateUpdateSchedule = (data) => {
    let errors = {}

    if (!data.jadwalSesi) errors.jadwalSesi = "New Schedule is required";

    return errors;
  }

  const updateSchedule = async (data, form) => {
    setLoading(true);

    putData(`edit-class-ptc-schedule`, data)
    .then(() => {
        setVisibility();
        toast.current.show({
            severity: 'info',
            summary: 'Update Success',
            detail: 'PTC successfully re-scheduled, enjoy your time.',
            life: 3000
        });
        setLoading(false);
        refreshAction();
        form.restart();
    })
    .catch((error) => {
      if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
        toast.current.show({
          severity: 'error',
          summary: 'Update Failed!',
          detail: error.response.data.message,
          life: 3000
        });
      }

      setLoading(false)
    });
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);

  const getFormErrorMessage = (meta) => {  
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  return (
    <Dialog
        visible={visibility}
        onHide={() => setVisibility()}
        header="Update PTC Schedule"
        style={{width: '25rem'}}
        breakpoints={{'960px': '75vw', '640px': '95vw'}}
    >
        <Form 
            initialValues={initialValues}
            validate={validateUpdateSchedule}
            onSubmit={updateSchedule}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} className='flex flex-column gap-3'>
                    <Field
                        name='jadwalSesi'
                        render={({meta, input}) => (
                            <div className='flex-auto'>
                                <label className='font-bold block'>Jadwal</label>
                                <Calendar 
                                    id='jadwal'
                                    name='jadwal'
                                    {...input}
                                    showIcon
                                    showTime
                                    hourFormat='24'
                                    dateFormat='dd MM yy -'
                                    className='w-full'
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex gap-2 lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center w-full'>
                        <Button label="Cancel" icon="pi pi-times" type='button' outlined onClick={() => setVisibility()} loading={loading} className='w-max'/>
                        <Button label="Save" icon="pi pi-check" type='submit' className='w-max' loading={loading}/>
                    </div>
                </form>
            )}
        />
    </Dialog>
  )
}

export default FormUpdateSchedule
