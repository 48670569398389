import React, { useEffect, useRef, useState } from 'react';
import SessionHistory from '../DetailClass/SessionHistory';
import { getData } from '../../Hooks/api';
import { useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';

const Recording = () => {
  const {id} = useParams();
  const toast = useRef(null);
  const [detailClass, setDetailClass ] = useState([]);

  const getDetailClass = async () => {
    getData(`class/${id}`)
    .then((response) => {
        setDetailClass(response.data);
    })
    .catch((error) => {
      if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
        toast.current.show({
          severity: 'error',
          summary: 'Load Failed!',
          detail: error.response.data.message,
          life: 3000
        });
      }
    })
  }

  useEffect(() => {
    getDetailClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
        <Toast ref={toast} />
        <SessionHistory dataHistory={detailClass.historyZoom} type='CARD' detailClass={detailClass}/>
    </div>
  )
}

export default Recording
