import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Panel } from 'primereact/panel'
import { Tag } from 'primereact/tag'
import React, { useEffect, useState } from 'react'
import { formattedDateWithDay } from '../../../Hooks/helper'
import { getSeverity } from '../../../Constant/general'
import FormUpdateSchedule from './FormUpdateSchedule'
import { useParams } from 'react-router-dom'
import FormDetailPTC from './FormDetailPTC'
import FormContinuation from './FormContinuation'
import FormCreatePTC from './FormCreatePTC'
import { putData } from '../../../Hooks/api'
import NegativeConfirmDialog from '../../../Components/ConfirmDialog'

const PTC = ({
  listPTC, 
  toast, 
  ptcLength, 
  classStatus, 
  refreshAction, 
  loading, 
  setLoading,
  detailClass
}) => {
  const {id} = useParams();
  const [ptc, setPTC] = useState([]);
  
  // Dialog Handler
  const [ continuationDialog, setContinuationDialog ] = useState(false);
  const [ updateScheduleDialog, setUpdateScheduleDialog ] = useState(false);
  const [ PTCUpdateDialog, setPTCUpdateDialog ] = useState(false);
  const [ selectedPTCCell, setSelectedPTCCell ] = useState(null);
  const [ createPTCDialog, setCreatePTCDialog ] = useState(false);
  const [ removePTCDialog, setRemovePTCDialog ] = useState(false)

  // Data Handler 
  const [ dataScheduleUpdate, setDataScheduleUpdate ] = useState({
    jadwalSesi: '',
    indexSesi: '',
    classId: id
  });
  const [ detailContinuation, setDetailContinuation ] = useState({
    attendanceId: "",
    studentName: "",
    kelanjutanSiswa: "",
    alasan: ""
  });
  const [ detailSessionPTC, setDetailSessionPTC ] = useState({
    attendanceId: "",
    indexPTC: "",
    studentName: "",
    jadwal: "",
    reviewPTC: "",
    statusPTC: ""
  });
  const [ removeSchedulePTC, setRemoveSchedulePTC ] = useState({
    indexPTC: null,
    classId: null
  });

  useEffect(() => {
    if (listPTC) setPTC(listPTC); 
    if (detailClass) setRemoveSchedulePTC({
      indexPTC: null,
      classId: detailClass._id
    });
  }, [listPTC, detailClass]);

  const removePTC = () => {
    setLoading(true);

    putData(`remove-shcedule-ptc`, removeSchedulePTC)
    .then(() => {
      setTimeout(() => {
        refreshAction();
        toast.current.show({
            severity: 'info',
            summary: 'Remove Success',
            detail: "Successfully remove PTC schedule",
            life: 3000
        });

        if(!loading) setRemovePTCDialog(false);
      }, 1000);
    })
    .catch((error) => {
      if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
        toast.current.show({
          severity: 'danger',
          summary: 'Remove Failed!',
          detail: error.response.data.message,
          life: 3000
        });
      }

      setLoading(false)
    });
  }

  const attendancePTC = (ptc) => {
    const column = [];
    
    if (ptc !== null && ptc.length > 0) {
        for (let index = 1; index <= ptcLength; index++) {
            column.push(<Column 
              key={index} 
              header={(e) => headerPTCColumnTemplate(e, index)} 
              body={(e) => bodyPTCColumnTemplate(e, index)} 
              style={{minWidth: '20rem', width: '20rem'}}
            ></Column>);
        }
    }
    
    return column;
  };

  const headerPTCColumnTemplate = (element, index) => {
    const dataColumn = {
      jadwalSesi: new Date(element.props.value[0][`jadwalPTC${index}`]),
      indexSesi: index-1,
      classId: id
    }

    const dataRemove = {
      indexPTC: index - 1,
      classId: id
    }

    return (
      <div className='flex align-items-center justify-content-between' style={{minWidth: '18rem', width: '18rem'}}>
        <div>PTC {index}</div>
        { classStatus !== "Selesai" && <div className='flex gap-1'>
            <Button icon="pi pi-pencil" severity='secondary' outlined className='border-circle' onClick={() => showDialog(dataColumn, "UPDATE_SCHEDULE")} />
            <Button icon="pi pi-trash" severity='danger' className='border-circle' onClick={() => showDialog(dataRemove, "REMOVE_SCHEDULE")} />
        </div>}
      </div>
    )
  };

  const showDialog = (data, type) => {
    if (type === "UPDATE_SCHEDULE") {
      setDataScheduleUpdate(data);
      setUpdateScheduleDialog(true);
    }

    if (type === "REMOVE_SCHEDULE") {
      setRemoveSchedulePTC(data);
      setRemovePTCDialog(true);
    }
  };

  const hideDialog  = (type) => {
    if (type === "DETAIL_PTC") {
      setDetailSessionPTC({
        attendanceId: "",
        indexPTC: "",
        studentName: "",
        jadwal: "",
        reviewPTC: "",
        statusPTC: ""
      });
      setSelectedPTCCell(null);
      setPTCUpdateDialog(false);
    }

    if (type === "CONTINUATION") {
      setDetailContinuation({
        attendanceId: "",
        studentName: "",
        kelanjutanSiswa: "",
        alasan: ""
      });
      setSelectedPTCCell(null);
      setContinuationDialog(false);
    }
  };

  const bodyPTCColumnTemplate = (rowData, index) => {
    return (
      <div className='flex flex-column gap-1'>
        <div className='flex justify-content-between align-items-center'>
          <div>
            <p style={{fontSize: '13px', margin: 0}}><strong>Tgl: </strong></p>
            <p style={{fontSize: '12px', margin: 0}}>{formattedDateWithDay(rowData[`jadwalPTC${index}`])}</p>
          </div>
          <Tag value={rowData[`kehadiranPTC${index}`]} severity={getSeverity(rowData[`kehadiranPTC${index}`], "ATTENDANCE_PTC")} className='text-center' />
        </div>
        <div>
          <p style={{fontSize: '13px', margin: 0}}><strong>Review Testimonial Parents: </strong></p>
          <p style={{fontSize: '12px', margin: 0}}>{rowData[`reviewPTC${index}`]}</p>
        </div>
      </div>
    )
  };

  const continuationStatusTemplate = (data) => {
    let studentContinuation = "PTC Belum Selesai"
    if (data.kelanjutan === "Berhenti" || data.kelanjutan === "Lanjut" || data.kelanjutan === "Lulus" ) studentContinuation = data.kelanjutan

    return (
      <div>
        <Tag value={studentContinuation} severity={getSeverity(studentContinuation, "CONTINUATION")} />
        <p className='mt-1 p-0 text-xs'>{data.alasanBerhenti || ""}</p>
      </div>
    )
  };

  const showPTCUpdate = (element) => {
    const { rowData, cellIndex, column } = element
    const indexPTC = cellIndex
    
    if (column.props.header === "Kelanjutan") {
      console.log(rowData);
       
      setDetailContinuation({
        attendanceId: rowData.id,
        studentName: rowData.nama,
        kelanjutanSiswa: rowData.kelanjutan,
        alasan: rowData.alasanBerhenti,
        klasifikasiBerhenti: ""
      })

      setContinuationDialog(true)
    } else {
      setDetailSessionPTC({
        attendanceId: rowData.id,
        indexPTC: indexPTC - 1,
        studentName: rowData.nama,
        jadwal: rowData[`jadwalPTC${indexPTC}`],
        reviewPTC: rowData[`reviewPTC${indexPTC}`],
        statusPTC: rowData[`kehadiranPTC${indexPTC}`]
      })
  
      setPTCUpdateDialog(true)
    }
  };

  const isCellPTCSelectable = (event) => {
    if (classStatus !== "Selesai")
      return (event.data.field === 'nama' ? false : true)
    if (classStatus === "Selesai")
      return false
  };

  const headerTableTemplate = () => (
    <div className='flex lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center '>
      <Button label='+ Create PTC' size='small' className={classStatus === "Selesai" ? 'hidden' : 'lg:w-max md:w-max sm:w-max w-full'} onClick={() => setCreatePTCDialog(true)}/>
    </div>
  )

  return (
    <Panel header="Parent Teacher Conference" toggleable collapsed={true}>
        <NegativeConfirmDialog
          visible={removePTCDialog}
          visibleHandler={() => setRemovePTCDialog(false)}
          rejectAction={() => setRemovePTCDialog(false)}
          acceptAction={removePTC}
          loading={loading}
          titleConfirmation="Confirmation"
          messageConfirmation="Are you sure to delete this PTC schedule?"
          acceptLabelButton="Delete"
        />

        <FormCreatePTC 
          visible={createPTCDialog}
          visibleHandler={() => setCreatePTCDialog(false)}
          loading={loading}
          setLoading={setLoading}
          toast={toast}
          listAttendance={detailClass.absensi}
          refreshAction={refreshAction}
        />
        <FormUpdateSchedule 
          visibility={updateScheduleDialog} 
          setVisibility={() => setUpdateScheduleDialog(false)} 
          currentSchedule={dataScheduleUpdate} 
          refreshAction={refreshAction}
          toast={toast}
        />
        
        <FormDetailPTC
          visibility={PTCUpdateDialog}
          setVisibility={() => hideDialog("DETAIL_PTC")}
          toast={toast}
          refreshAction={refreshAction}
          data={detailSessionPTC}
          loading={loading}
          setLoading={setLoading}
        />
        
        <FormContinuation 
          visibility={continuationDialog} 
          setVisibility={() => hideDialog("CONTINUATION")} 
          toast={toast}
          refreshAction={refreshAction}
          data={detailContinuation}
          loading={loading}
          setLoading={setLoading}
        />
        
        <DataTable
          value={listPTC}
          removableSort
          scrollable
          cellSelection
          selectionMode="single"
          selection={selectedPTCCell}
          loading={loading}
          onSelectionChange={(e) => setSelectedPTCCell(e.value)}
          metaKeySelection={false}
          onCellSelect={(e) => showPTCUpdate(e)}
          isDataSelectable={isCellPTCSelectable}
          header={headerTableTemplate}
          className='lg:text-sm md:text-sm sm:text-sm text-xs'
        >
            <Column field='nama' header="Nama Siswa" style={{minWidth: '15rem', width: '20rem'}}/>
            {attendancePTC(ptc)}
            <Column field='kelanjutan' header="Kelanjutan" body={continuationStatusTemplate} alignHeader="center" align="center" style={{minWidth: '15rem', width: '20rem'}}/>
        </DataTable>
    </Panel>
  )
}

export default PTC