import { Card } from 'primereact/card'
import React from 'react'

const DashJumbotron = ({nickName}) => {
  return (
    <Card className='w-full'>
        <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column lg:gap-4 md:gap-4 gap-3 lg:align-items-center md:align-items-center sm:align-items-center'>
        <img src='../img/welcome-teacher.png' alt='welcome' className='max-w-20rem w-8'/>
        <div>
            <h3 className='lg:text-3xl md:text-3xl sm:text-2xl text-sm m-0 p-0'>Welcome Back, {nickName}!</h3>
            <p className='lg:text-xl md:text-xl sm:text-xl text-xs m-0 p-0'>
            Jangan lupa mengawali hari dengan bacaan bismillah, ya. Semoga kegiatan belajar mengajarnya dilancarkan dan tetap semangat...
            </p>
        </div>
        </div>
    </Card>
  )
}

export default DashJumbotron
