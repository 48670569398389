export const FUNNEL_OPTIONS = ["Organik", "DM IG", "Iklan IG", "Iklan FB"];
export const classType = ["Small", "Private", "Edu Ramadhan"];
export const subjectClass = ["Adab", "AFQ", "IC Atas", "IC Bawah", "ICWS - ST", "ICWS - W", "IE", "NI", "IL & SoIP", "Robotika", "QT"];
export const rangeAges = ["5 - 7", "8 - 10", "11 - 13", "> 13"];
export const PTCAttendance = ['Hadir', 'Tidak Hadir', 'Belum PTC'];
export const SessionAttendance = ['Izin', 'Sakit', 'Hadir', 'Belum diabsen', 'Tidak Hadir'];
export const hours24 = Array.from({length: 24}, (_, i) => (i).toString().padStart(2, '0'));
export const hours12 = Array.from({length:  12}, (_, i) => (i).toString().padStart(2, '0'));
export const stopReason = ["FINANCIAL", "REGULAR CLASS", "OCCUPIED SCHEDULE", "NO RESPONSE"];
export const LogoEdufic = './edufic-logo.png';
const hari = ['Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu'];
const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const List_CHART_COLOR = [
    '--green-400',
    '--yellow-400',
    '--primary-400',
    '--blue-400',
    '--surface-500',
    '--cyan-400',
    '--pink-400',
    '--indigo-400',
    '--teal-400',
    '--orange-400',
    '--bluegray-400',
    '--red-400',
    '--purple-600',
    '--surface-700',
    '--primary-700',
    '--blue-700',
    '--green-500',
    '--yellow-500',
    '--cyan-600',
    '--pink-600',
    '--indigo-700',
    '--teal-700',
    '--orange-700',
    '--bluegray-700',
    '--purple-900',
    '--red-800'
];

export const getSeverity = (data, type) => {
    if (type === "CLASS_TYPE") {
        switch (data) {
            case "Small":
                return 'info';

            default:
                return 'warning';
        }
    }

    if (type === "ATTENDANCE_PTC") {
        switch (data) {
            case "Hadir":
                return 'success'
            
            case "Belum Diabsen": 
                return 'info'
            
            case "Tidak Hadir":
                return 'danger'

            default:
                return 'info';
        }
    }

    if (type === "CONTINUATION") {
        switch (data) {
            case "Berhenti":
                return 'danger'
            
            case "Lanjut": 
                return 'success'
            
            case "Lulus":
                return 'info'

            default:
                return 'warning';
        }
    }

    if (type === "SALARY") {
        switch (data) {
            case 'Terbayar':
                return 'success'
            
            case 'Diangsur':
                return 'warning'
            
            case 'Belum dibayar': 
                return 'danger'
        
            default:
                return 'info';
        }
    }

    if (type === "MOOD_SCALE") {
        switch (data) {
            case "Increase":
                return 'success';

            case "Decrease": 
                return 'danger';
            
            case "Steady":
                return 'info'
        
            default:
                return 'warning';
        }
    }
}

export const getDays = (locale) => {
    if (locale === "ID") return hari;
    else return days;
}

export const getDayIcon = (data) => {
    const day = data.toUpperCase();

    if (day === "SUNDAY" || day === "MINGGU") return '../icons/sunday.png';
    if (day === "MONDAY" || day === "SENIN") return '../icons/monday.png';
    if (day === "TUESDAY" || day === "SELASA") return '../icons/tuesday.png';
    if (day === "WEDNESDAY" || day === "RABU") return '../icons/wednesday.png';
    if (day === "THURSDAY" || day === "KAMIS") return '../icons/thursday.png';
    if (day === "FRIDAY" || day === "JUMAT") return '../icons/friday.png';
    if (day === "SATURDAY" || day === "SABTU") return '../icons/saturday.png';
}