import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { getSeverity, SessionAttendance } from '../../../Constant/general';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { postData } from '../../../Hooks/api';
import { checkMoodIndicator, moodScale } from '../../../Constant/mood';
import { Tag } from 'primereact/tag';

const UpdateDetailSession = ({
  visible,
  visibleHandler,
  refreshAction,
  loading,
  setLoading,
  toast,
  detailSession
}) => {
  const [ initialValues, setInitialValues ] = useState({
    attendanceId: "",
    sessionIndex: "",
    newStatus: "",
    review: "",
    studentName: "",
    moodAfter: 0,
    moodBefore: 0
  });

  useEffect(() => {
    if (detailSession) setInitialValues({...detailSession});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailSession])

  const validateDetailAttendande = (data) => {
    let errors = {};

    if (!data.newStatus) errors.newStatus = "Status is required";
    if (!data.review) errors.review = "Review is required";

    return errors;
  }

  const updateDetailSession = async (data, form) => {
    setLoading(true);

    postData(`editStatus`, data)
    .then(() => {
        setTimeout(() => {
            refreshAction();
            toast.current.show({
                severity: 'info',
                summary: 'Update Success',
                detail: 'Detail Session successfully updated.',
                life: 3000
            });
            form.restart();

            if (!loading) visibleHandler();
        }, 1000);
    })
    .catch((error) => {
        if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
            toast.current.show({
                severity: 'error',
                summary: 'Update Failed!',
                detail: error.response.data.message,
                life: 3000
            });
        }
        setLoading(false);
    });
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);

  const getFormErrorMessage = (meta) => {  
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const moodChangeHandler = (event, input, values) => {    
    if (values === event) input.onChange(0);
    else input.onChange(parseInt(event ? event : 0));
  }

  return (
    <Dialog
      header="Detail Attendance"
      visible={visible}
      onHide={visibleHandler}
      style={{ width: '30rem' }} 
      breakpoints={{ '960px': '75vm', '641px': '90vw' }}
    >
        <Form
            initialValues={initialValues}
            validate={validateDetailAttendande}
            onSubmit={updateDetailSession}
            render={({handleSubmit, values}) => (
                <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                    <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column lg:gap-2 md:gap-2 sm:gap-2 gap-3'>
                        <div className='flex-auto'>
                            <label className='font-bold block mb-2'>Student Name</label>
                            <InputText
                                id='studentName'
                                name='studentName'
                                value={initialValues.studentName}
                            />
                        </div>
                        <div className='flex-auto'>
                            <label className='font-bold block mb-2'>Session</label>
                            <InputText
                                id='sessionIndex'
                                name='sessionIndex'
                                value={initialValues.sessionIndex}
                            />
                        </div>
                    </div>
                    <Field 
                        name='newStatus'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label className='font-bold block mb-2'>Attendance Status</label>
                                <Dropdown
                                    id='newStatus'
                                    name='newStatus'
                                    options={SessionAttendance}
                                    {...input}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div>
                        <div className='flex justify-content-between align-items-center mb-2'>
                            <label className='font-bold block'>Mood Scale</label>
                            <Tag 
                              value={checkMoodIndicator(values.moodBefore, values.moodAfter)} 
                              severity={getSeverity(checkMoodIndicator(values.moodBefore, values.moodAfter), "MOOD_SCALE")}
                            />
                        </div>
                        <div className='flex flex-column gap-2'>
                            <Field
                                name='moodBefore'
                                render={({input, meta}) => (
                                    <div className='flex-auto'>
                                        <label className='font-bold block text-sm'>Before Session</label>
                                        <div className='flex flex-wrap gap-1 align-items-end lg:justify-content-between md:justify-content-between sm:justify-content-between justify-content-around text-center'>
                                            {
                                                moodScale.map((mood) => (
                                                    <div className='flex flex-column align-items-center' key={mood.value}  onClick={() => moodChangeHandler(mood.value, input, values.moodBefore)}>
                                                        <img 
                                                            src={values.moodBefore === mood.value ? mood.srcActive : mood.srcPassive} 
                                                            alt={mood.name}
                                                            width={values.moodBefore === mood.value ? 60 : 50}
                                                            {...input}
                                                        />
                                                        <label className={values.moodBefore === mood.value ? 'text-xs font-bold' : 'text-xs'}>{mood.label}</label>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )}
                            />
                            
                            <Field
                                name='moodAfter'
                                render={({input, meta}) => (
                                    <div className='flex-auto'>
                                        <label className='font-bold block text-sm'>After Session</label>
                                        <div className='flex flex-wrap gap-1 align-items-end lg:justify-content-between md:justify-content-between sm:justify-content-between justify-content-around text-center'>
                                            {
                                                moodScale.map((mood) => (
                                                    <div className='flex flex-column align-items-center' key={mood.value} onClick={() => moodChangeHandler(mood.value, input, values.moodAfter)}>
                                                        <img 
                                                            src={values.moodAfter === mood.value ? mood.srcActive : mood.srcPassive} 
                                                            alt={mood.name}
                                                            width={values.moodAfter === mood.value ? 60 : 50}
                                                            {...input}
                                                        />
                                                        <label className={values.moodAfter === mood.value ? 'text-xs font-bold' : 'text-xs'}>{mood.label}</label>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                    <Field 
                        name='review'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label className='font-bold block mb-2'>Session Review</label>
                                <InputTextarea
                                    id='review'
                                    name='review'
                                    rows={3}
                                    {...input}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex gap-2 lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center w-full'>
                        <Button label="Cancel" icon="pi pi-times" type='button' severity='danger' outlined onClick={() => visibleHandler()} loading={loading} className='w-max'/>
                        <Button label="Update" icon="pi pi-check" type='submit' className='w-max' loading={loading}/>
                    </div>
                </form>
            )}
        />
    </Dialog>
  )
}

export default UpdateDetailSession
