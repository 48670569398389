export const moodScale = [
    {
        name: "Deep Sadness",
        label: "Menangis",
        srcActive: "/img/mood/active/Very Very Sad.png",
        srcPassive: "/img/mood/passive/Very Very Sad.png",
        value: 1,
    },
    {
        name: "Very Sad",
        label: "Sedih",
        srcActive: "/img/mood/active/Very Sad.png",
        srcPassive: "/img/mood/passive/Very Sad.png",
        value: 2,
    },
    {
        name: "Sad",
        label: "Lesu",
        srcActive: "/img/mood/active/Sad.png",
        srcPassive: "/img/mood/passive/Sad.png",
        value: 3
    },
    {
        name: "Smile",
        label: "Senyumin",
        srcActive: "/img/mood/active/Smile.png",
        srcPassive: "/img/mood/passive/Smile.png",
        value: 4
    },
    {
        name: "Happy",
        label: "Senang",
        srcActive: "/img/mood/active/Happy.png",
        srcPassive: "/img/mood/passive/Happy.png",
        value: 5
    },
    {
        name: "Very Happy",
        label: "Bahagia",
        srcActive: "/img/mood/active/Very Happy.png",
        srcPassive: "/img/mood/passive/Very Happy.png",
        value: 6
    },
    {
        name: "Excited",
        label: "Membara",
        srcActive: "/img/mood/active/Very Very Happy.png",
        srcPassive: "/img/mood/passive/Very Very Happy.png",
        value: 7
    }
];

export const checkMoodIndicator = (before, after) => {
    let status = "Abstain";
    
    if (before && after) {
        if (before > after) status = "Decrease";
        if (before < after) status = "Increase";
        if (before === after) status = "Steady";
    }
    
    return status;
}