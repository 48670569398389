import React, { useRef } from 'react';
import FormRegister from './FormRegister';
import { Toast } from 'primereact/toast';

const Register = () => {
  const toast = useRef(null);

  return (
    <div className='pt-5'>
      <Toast ref={toast}/>
      <div className='mt-5 pt-5'>
        <FormRegister toast={toast}/>
      </div>
    </div>
  )
}

export default Register
