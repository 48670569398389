import React, { useEffect, useRef, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom';
import OverviewClass from './Overview';
import { getData, postData, putData } from '../../Hooks/api';
import { Toast } from 'primereact/toast';
import SessionHistory from './SessionHistory';
import Attendance from './Attendance';
import PTC from './PTC';
import { Button } from 'primereact/button';
import NegativeConfirmDialog from '../../Components/ConfirmDialog';

const DetailClass = () => {
  const {teacher} = useOutletContext();
  const toast = useRef(null);
  const {id} = useParams();
  const [loading, setLoading] = useState(false);
  const [ detailClass, setDetailClass ] = useState({});
  const [ listAttendance, setListAttendance ] = useState([]);
  const [ lengthPTC, setLengthPTC ] = useState(0);
  const [ lengthSession, setLengthSession ] = useState(0);
  const [ listPTC, setListPTC ] = useState([]);
  const [ showEndClassDialog, setShowEndClassDialog ] = useState(false);

  const getDetailClass = async () => {
    getData(`class/${id}`)
    .then((response) => {
      setDetailClass(response.data);
    })
    .catch((error) => {
      if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
        toast.current.show({
          severity: 'error',
          summary: 'Load Failed!',
          detail: error.response.data.message,
          life: 3000
        });
      }
    })
  }

  const getDataAttendance = async () => {  
    setLoading(true);
    postData(`attendanceByIds`, {attendanceId: detailClass.absensi})
    .then((response) => {
      const dataAttendance = response.data;

      setListAttendance(attendanceModify(dataAttendance));
      setLengthSession(dataAttendance[0].session.length);
      
      if (dataAttendance && dataAttendance[0].sessionPTC && dataAttendance[0].sessionPTC.length > 0) {
        setListPTC(ptcModify(dataAttendance));
        setLengthPTC(dataAttendance[0].sessionPTC.length);
      } else {
        setLengthPTC(0);
        setListPTC([]);
      }

      setLoading(false);
    })
    .catch((error) => {
      if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
        toast.current.show({
          severity: 'error',
          summary: 'Load Failed!',
          detail: error.response.data.message,
          life: 3000
        });
      }
      setLoading(false);
    })
  }

  const attendanceModify = (dataAttendance) => {
    const data = []
    dataAttendance.map((row) => {
      const generalData = {
        id: row._id,
        nie: row.studentNIE,
        student: row.studentName,
        moodIncreased: row.moodIncreased,
        moodDecreased: row.moodDecreased,
        moodSteady: row.moodSteady
      }

      for(let i = 0; i < row.session.length; i++){
        generalData['sesi'+row.session[i].sesi] = row.session[i].status
        generalData['tanggalSesi'+row.session[i].sesi] = row.session[i].scheduledDate
        generalData['review'+row.session[i].sesi] = row.session[i].review
        generalData['statusGaji'+row.session[i].sesi] = row.session[i].digaji
        generalData['moodBefore'+row.session[i].sesi] = row.session[i].moodBefore
        generalData['moodAfter'+row.session[i].sesi] = row.session[i].moodAfter
      }
      
      data.push(generalData);
      
      return generalData;
    });

    return data;
  }

  const ptcModify = (dataPTC) => {
    const data = []
  
    if (dataPTC != null && Array.isArray(dataPTC)) {
      dataPTC.map((row) => {
        const generalData = {
          id: row._id,
          nama: row.studentName,
          kelanjutan: row.kelanjutan,
          alasanBerhenti: row.alasanBerhenti,
          jumlahPTC: row.sessionPTC.length
        }

        const PTC = row.sessionPTC || []

        if (PTC.length > 0) {
          for(let i = 0; i < PTC.length; i++) {
            const detailSesiPTC = PTC[i]
            generalData[`jadwalPTC${i+1}`] = detailSesiPTC.scheduledDate
            generalData[`kehadiranPTC${i+1}`] = detailSesiPTC.status
            generalData[`reviewPTC${i+1}`] = detailSesiPTC.review
          }
        }
  
        data.push(generalData)
        return row;
      })
    }
    
    return data;
  }

  useEffect(() => {
    getDetailClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (detailClass.absensi && detailClass.absensi?.length > 0) getDataAttendance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailClass]);

  const endClass = async () => {
    setLoading(true);

    putData(`end-class`, {classId: detailClass._id})
    .then(() => {
      setTimeout(() => {
        setShowEndClassDialog(false);
        getDetailClass();
        toast.current.show({
          severity: 'success',
          summary: 'End Class Success',
          detail: "Successfully end class, good job!",
          life: 3000
        });
        setLoading(false);
      }, 1000);
    })
    .catch((error) => {
      if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
        toast.current.show({
          severity: 'error',
          summary: 'Load Failed!',
          detail: error.response.data.message,
          life: 3000
        });
      }
      setLoading(false);
    })
  }

  return (
    <div className='flex flex-column gap-3'>
      <Toast ref={toast} />
      <NegativeConfirmDialog 
        visible={showEndClassDialog}
        visibleHandler={() => setShowEndClassDialog(false)}
        acceptAction={endClass}
        rejectAction={() => setShowEndClassDialog(false)}
        titleConfirmation="End Class"
        messageConfirmation="Are you sure want to end class?"
        acceptLabelButton="End Class"
      />
      <OverviewClass 
        account={teacher} 
        toast={toast} 
        detailClass={detailClass}
        dataAttendance={listAttendance}
        lengtSession={lengthSession}
        lengthPTC={lengthPTC}
        loading={loading}
        setLoading={setLoading}
        getDataAttendance={getDataAttendance}
        getDetailClass={getDetailClass}
      />
      <Attendance 
        listAttendance={listAttendance} 
        toast={toast} 
        loading={loading} 
        setLoading={setLoading}
        sessionLength={lengthSession}
        detailClass={detailClass}
        classStatus={detailClass.statusKelas}
        refreshAction={() => getDetailClass()}
      />
      {lengthPTC > 0 &&  
        <PTC 
          listPTC={listPTC} 
          ptcLength={lengthPTC} 
          toast={toast} 
          classStatus={detailClass.statusKelas} 
          refreshAction={() => getDataAttendance()} 
          loading={loading}
          setLoading={setLoading}
          detailClass={detailClass}
        />
      }
      <SessionHistory type='PANEL' detailClass={detailClass} toast={toast} />
      <div className='flex justify-content-center'>
        <Button 
          icon='pi pi-flag-fill' 
          iconPos='right' 
          label='End Class' 
          severity='danger' 
          className={(listPTC.length > 0 && detailClass.statusKelas !== "Selesai") ? 'lg:w-6 md:w-6 sm:w-6 w-full' : 'hidden'} 
          size='small'
          onClick={() => setShowEndClassDialog(true)}
        />
      </div>
    </div>
  )
}

export default DetailClass