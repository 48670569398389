import { Panel } from 'primereact/panel'
import React, { useEffect, useState } from 'react'
import { classType, rangeAges, subjectClass } from '../../Constant/general'
import { Checkbox } from 'primereact/checkbox'
import { Button } from 'primereact/button'
import { postData } from '../../Hooks/api'
import { checkDiffObject } from '../../Hooks/helper'

const ClassForm = ({
  toast, 
  preference, 
  refreshAction = () => {},
  idSchedule
}) => {
  const [ diffObject, setDiffObject ] = useState(false);

  const [ preferenceUpdate, setPreferenceUpdate ] = useState({
    jenisKelas: [],
    rentangUsia: [],
    subject: [],
    jadwal: []
  });

  useEffect(() => {
    if (preference) setPreferenceUpdate(preference);
  }, [preference])

  const cancelAction = () => {
    setPreferenceUpdate(preference);
    setDiffObject(false);
  }

  const updatePreference = async () => {
    const data = {
        usia: preferenceUpdate.rentangUsia,
        jenisKelas: preferenceUpdate.jenisKelas,
        subjek: preferenceUpdate.subject
    };

    postData(`update-available-schedule/${idSchedule}`, data)
    .then(() => {
        refreshAction();
        toast.current.show({
          severity: 'info',
          summary: 'Update Success',
          detail: 'Preference successfully updated, thanks for your attention.',
          life: 3000
        });
        setDiffObject(false);
    })
    .catch((error) => {
      if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
        toast.current.show({
          severity: 'error',
          summary: 'Update Failed!',
          detail: error.response.data.message,
          life: 3000
        });
      }
    })
  }

  const onChangePreference = (e, keyObject) => {
    let classType = [...preferenceUpdate[keyObject]];
    
    if (e.checked) classType.push(e.value);
    else classType.splice(classType.indexOf(e.value), 1);

    let newPreference = {...preferenceUpdate, [keyObject]: classType};

    setPreferenceUpdate(newPreference);

    setDiffObject(checkDiffObject(preference, newPreference));
  };
  
  return (
    <Panel header="Class Preference" toggleable>
        <div className='flex flex-column gap-3'>
            <div>
                <label className='block font-bold mb-2'>Tipe Kelas</label>
                <div className='flex flex-wrap gap-2'>
                    {
                        classType.map((jenisKelas) => (
                            <div className="flex align-items-center" key={jenisKelas}>
                                <Checkbox inputId={jenisKelas} name={jenisKelas} value={jenisKelas} onChange={(e) => onChangePreference(e, "jenisKelas")} checked={preferenceUpdate.jenisKelas.includes(jenisKelas)} />
                                <label htmlFor={jenisKelas} className="ml-2">{jenisKelas}</label>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div>
                <label className='block font-bold mb-2'>Rentang Usia</label>
                <div className='flex flex-wrap gap-2'>
                    {
                        rangeAges.map((ages) => (
                            <div className="flex align-items-center" key={ages}>
                                <Checkbox inputId={ages} name={ages} value={ages} onChange={(e) => onChangePreference(e, "rentangUsia")} checked={preferenceUpdate.rentangUsia.includes(ages)} />
                                <label htmlFor={ages} className="ml-2">{ages}</label>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div>
                <label className='block font-bold mb-2'>Subjek</label>
                <div className='flex flex-wrap gap-2'>
                    {
                        subjectClass.map((subject) => (
                            <div className="flex align-items-center" key={subject}>
                                <Checkbox inputId={subject} name={subject} value={subject} onChange={(e) => onChangePreference(e, "subject")} checked={preferenceUpdate.subject.includes(subject)} />
                                <label htmlFor={subject} className="ml-2">{subject}</label>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='flex gap-2 justify-content-end mt-3'>
              <Button label='Cancel' severity='danger' outlined size='small' disabled={!diffObject} onClick={cancelAction}/>
              <Button label='Update' severity='primary' size='small' disabled={!diffObject} onClick={() => updatePreference()}/>
            </div>
        </div>
    </Panel>
  )
}

export default ClassForm
