import { Button } from 'primereact/button'
import React from 'react'
import { Link } from 'react-router-dom'

const MainFooter = () => {
  return (
    <div className='flex flex-column justify-content-end mt-auto'>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 180"><path fill="#263c32" fillOpacity="1" d="M0,32L40,32C80,32,160,32,240,64C320,96,400,160,480,170.7C560,181,640,139,720,112C800,85,880,75,960,80C1040,85,1120,107,1200,101.3C1280,96,1360,64,1400,48L1440,32L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path></svg>
      <div className='edu-bg-doff py-2'>
        <div className='flex lg:flex-row md:flex-row flex-column lg:gap-4 md:gap-4 sm:gap-4 gap-2 lg:align-items-start md:align-items-start align-items-center justify-content-center mx-5 mb-4 lg:text-left md:text-left text-center'>
            <img src='../img/edufic-logo.png' alt='Logo Edufic' className='w-10 max-w-10rem mx-3 lg:mt-0 md:mt-0 sm:mt-0 mt-5'/>
            <div className='mx-3'>
                <h3>About Us</h3>
                <div className='flex flex-column gap-2'>
                    <Link to="https://edufic.id" target='_blank' className='text-white'>Edufic.id</Link>
                    <Link to="#" className='text-white'>Our Teachers</Link>
                    <Link to="https://edufic.id/events/" target='_blank' className='text-white'>Edufic Event</Link>
                    <Link to="https://edufic.id/blog/" target='_blank' className='text-white'>Edufic Blog</Link>
                </div>
            </div>
            <div className='mx-3'>
                <h3 className=''>Need Help?</h3>
                <div className='flex flex-column gap-2'>
                    <Link to="https://wa.me/6282244898420" target='_blank'>
                        <Button icon="pi pi-whatsapp" label='Faiq' size='small' className='py-1 px-2 border-round-2xl w-full'/>
                    </Link>
                    <Link to="https://wa.me/6282244898420" target='_blank'>
                        <Button icon="pi pi-whatsapp" label='Haidar' size='small' className='py-1 px-2 border-round-2xl w-full'/>
                    </Link>
                </div>
            </div>
            <div className='mx-3'>
                <h3>Follow Us</h3>
                <div className='flex gap-2'>
                    <Link to='https://www.facebook.com/edufic.edufic' target='_blank'><Button icon="pi pi-facebook" className='border-circle bg-blue-500 border-blue-500'/></Link>
                    <Link to='https://www.instagram.com/edufic.id' target='_blank'><Button icon="pi pi-instagram" className='border-circle bg-pink-500 border-pink-500'/></Link>
                    <Link to='https://www.youtube.com/@edufic_ID' target='_blank'><Button icon="pi pi-youtube" className='border-circle bg-red-600 border-red-600'/></Link>
                </div>
            </div>
        </div>
        <hr className='mx-5'/>
        <p className='p-0 m-0 text-center'>&#169; 2024 IT Edufic. All rights reserved.</p>
      </div>
      
    </div>
  )
}

export default MainFooter
