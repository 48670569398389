import { Calendar } from 'primereact/calendar'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { putData } from '../../../Hooks/api'
import { Button } from 'primereact/button'
import { SelectButton } from 'primereact/selectbutton'

const UpdateSessionSchedule = ({
  visibility, 
  visibleHandler, 
  refreshAction, 
  loading,
  setLoading, 
  detailSession, 
  toast 
}) => {
  const [initialValues, setInitialValues] = useState({
    subject: null,
    indexSesi: null,
    jadwalSesi: null,
    classId: null,
    editSemua: false
  });

  useEffect(() => {
    if (detailSession) setInitialValues({
        subject: detailSession.subject,
        indexSesi:  detailSession.indexSesi,
        jadwalSesi: new Date(detailSession.jadwalSesi),
        classId:  detailSession.classId,
        editSemua: false
    });
  }, [detailSession]);

  const UpdateSessionSchedule = async (data, form) => {
    setLoading(true);

    putData('editScheduleDate', data)
    .then(() => {
        setTimeout(() => {
            refreshAction();
            toast.current.show({
                severity: 'info',
                summary: 'Update Success',
                detail: "Successfully update schedule",
                life: 3000
            });
            form.restart();

            if (!loading) visibleHandler();
        }, 1000);
    })
    .catch((error) => {
        if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
            toast.current.show({
                severity: 'error',
                summary: 'Update Failed!',
                detail: error.response.data.message,
                life: 3000
            });
        }
        
        setLoading(false);
    });
  }

  const validateSchedule = (data) => {
    let errors = {}
    
    if (!data.jadwalSesi) errors.jadwalSesi = "Schedule is requried";

    return errors;
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {  
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  return (
    <Dialog
        header="Update Session Schedule"
        visible={visibility}
        onHide={visibleHandler}
        style={{ width: '25rem' }} 
        breakpoints={{'960px': '75vw', '640px': '95vw'}}
    >
        <Form
            initialValues={initialValues}
            validate={validateSchedule}
            onSubmit={UpdateSessionSchedule}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                    <div className='flex-auto'>
                        <label htmlFor="subject" className='font-bold block mb-2'>Kelas</label>
                        <InputText id='subject' name='subject' value={initialValues.subject} autoFocus/>
                    </div>
                    <div className='flex-auto'>
                        <label htmlFor="indexSesi" className='font-bold block mb-2'>Sesi</label>
                        <InputText id='indexSesi' name='indexSesi' value={initialValues.indexSesi + 1} keyfilter="int" className='w-full' />
                    </div>
                    <Field 
                        name='jadwalSesi'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label htmlFor="jadwalSesi" className='font-bold block mb-2'>Jadwal</label>
                                <Calendar
                                    id='jadwalSesi'
                                    name='jadwalSesi'
                                    {...input}
                                    showIcon
                                    showTime
                                    hourFormat='24'
                                    dateFormat='dd MM yy -'
                                    className='w-full'
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />

                    <Field 
                        name='editSemua'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label htmlFor="editSemua" className='font-bold block mb-2'>Update jadwal setelahnya?</label>
                                <SelectButton
                                    id='editSemua'
                                    name='editSemua'
                                    {...input}
                                    options={[
                                        {label: "YES", value: true},
                                        {label: "No", value: false}
                                    ]}
                                    optionLabel='label'
                                    optionValue='value'
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />

                    <div className='flex lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center gap-2'>
                        <Button type='button' label='Cancel' severity='danger' className='w-max' outlined onClick={() => visibleHandler()} loading={loading}></Button>
                        <Button type='submit' label='Update' severity='success' className='w-max' loading={loading} autoFocus></Button>
                    </div>  
                </form>
            )}
        />
    </Dialog>
  )
}

export default UpdateSessionSchedule
