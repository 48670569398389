import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import React, { useRef, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { listFinanceAccount } from '../../Constant/bank';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { postData } from '../../Hooks/api';
import { useNavigate } from 'react-router-dom';

const FormRegister = ({toast}) => {
  const stepper = useRef(null);
  const navigate = useNavigate();
  const [ loading, setLoading ] = useState(false);
  const teacherData = {
    nama: "",
    panggilan: "",
    nomor: "",
    tempatLahir: "",
    tanggalLahir: "",
    domisili: "",
    pendidikan: "",
    namaBank: "",
    rekening: "",
    atasNamaRekening: "",
    email: "",
    password: "",
    confirmPassword: ""
  }

  const teacherValidate = (data) => {
    let errors = {};

    identityValidate(data, errors);
    bankValidate(data, errors);
    accountValidate(data, errors);

    return errors;
  }

  const identityValidate = (data, errors = {}, click = false) => {
    let validate = true;

    if (!data.nama) { 
        errors.nama = "Full name is required";
        validate = false;
    }

    if (!data.panggilan) {
        errors.panggilan = "Nick name is required";
        validate = false;
    }

    if (!data.nomor) { 
        errors.nomor = "Contact is required";
        validate = false;
    }

    if (!data.tempatLahir) { 
        errors.tempatLahir = "required";
        validate = false;
    }

    if (!data.tanggalLahir) { 
        errors.tanggalLahir = "required";
        validate  = false;
    }

    if (!data.domisili) { 
        errors.domisili = "Domicile is required";
        validate =  false;
    }

    if (!data.pendidikan) { 
        errors.pendidikan = "Education is required";
        validate = false;
    }
    
    if (validate && click) {
        if (!data.atasNamaRekening) data.atasNamaRekening = data.nama
        stepper.current.nextCallback();
    }
  }

  const bankValidate = (data, errors = {}, click = false) => {
    let validate = true;

    if (!data.namaBank) { 
        errors.namaBank = "Bank name is required";
        validate = false;
    }

    if (!data.rekening) {
        errors.rekening = "Bank account is required";
        validate = false;
    }

    if (!data.atasNamaRekening) { 
        errors.atasNamaRekening = "Bank account name is required";
        validate = false;
    }
    
    if (validate && click) {
        stepper.current.nextCallback();
    }
  }

  const accountValidate = (data, errors = {}, click = false) => {
    if (!data.email) { 
        errors.email = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
        errors.email = 'Invalid email address. E.g. example@domain.com';
    }

    if (!data.password) {
        errors.password = "Password is required";
    }

    if (!data.confirmPassword) { 
        errors.confirmPassword = "Confirm password is required";
    } else if (data.password !== data.confirmPassword) {
        errors.confirmPassword = "Missmatch confirm password";
    }
  }

  const teacherRegister = (data, form) => {
    setLoading(true);
    const dataPost = data;

    delete dataPost.confirmPassword;

    postData('teachers', dataPost)
    .then(() => {
        toast.current.show({
            severity: 'success',
            summary: 'Register Success!',
            detail: 'Your data will verified by admin, enjoy your time.',
            life: 3000
        });
        
        setTimeout(() => {
            setLoading(false);
            form.restart();
            navigate('/auth/login');
        }, 1500);
    })
    .catch((error) => {
        if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
            setTimeout(()  => {
                setLoading(false);
                toast.current.show({
                    severity: 'error',
                    summary: 'Register Failed!',
                    detail: error.response.data.message,
                    life: 3000
                });
            }, 1500);
        }
    })

  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  return (
    <Form
        initialValues={teacherData}
        validate={teacherValidate}
        onSubmit={teacherRegister}
        render={({handleSubmit,values}) => (
            <form onSubmit={handleSubmit}>
                <Stepper ref={stepper} orientation='vertical' linear>
                    <StepperPanel header='General Identity'>
                        <Card className='p-fluid ml-2'>
                            <div className='flex flex-column gap-2'>
                                <Field 
                                    name='nama'
                                    render={({input, meta}) => (
                                        <div className='flex-auto'>
                                            <label htmlFor="nama" className="font-bold block mb-1">Full Name</label>
                                            <InputText 
                                                id="nama" 
                                                name='nama' 
                                                {...input} 
                                                invalid = {isFormFieldValid(meta)}
                                                disabled={loading}
                                            />
                                            {getFormErrorMessage(meta)} 
                                        </div>
                                    )}
                                />
                                <Field 
                                    name='panggilan'
                                    render={({input, meta}) => (
                                        <div className='flex-auto'>
                                            <label htmlFor="panggilan" className="font-bold block mb-1">Nick Name</label>
                                            <InputText 
                                                id="panggilan" 
                                                name='panggilan' 
                                                {...input} 
                                                invalid = {isFormFieldValid(meta)}
                                                disabled={loading}
                                            />
                                            {getFormErrorMessage(meta)} 
                                        </div>
                                    )}
                                />
                                <Field 
                                    name='nomor'
                                    render={({input, meta}) => (
                                        <div className='flex-auto'>
                                            <label htmlFor="nomor" className="font-bold block mb-1">Contact</label>
                                            <InputText 
                                                id="nomor" 
                                                name='nomor' 
                                                {...input} 
                                                invalid = {isFormFieldValid(meta)}
                                                disabled={loading}
                                                keyfilter="int"
                                            />
                                            {getFormErrorMessage(meta)} 
                                        </div>
                                    )}
                                />
                                <div className='flex-auto'>
                                    <label htmlFor="tempatLahir" className="font-bold block mb-1">Place and Date of Birth</label>
                                    <div className='flex align-items-end gap-1'>
                                        <Field 
                                            name='tempatLahir'
                                            render={({input, meta}) => (
                                                <div className='w-4'>
                                                    <InputText 
                                                        id="tempatLahir" 
                                                        name='tempatLahir' 
                                                        {...input} 
                                                        invalid = {isFormFieldValid(meta)}
                                                        disabled={loading}
                                                        placeholder='Kota / Kabupaten'
                                                    />
                                                    {getFormErrorMessage(meta)} 
                                                </div>
                                            )}
                                        />
                                        <span className='text-lg'>,</span>
                                        <Field 
                                            name='tanggalLahir'
                                            render={({input, meta}) => (
                                                <div className='w-8'>
                                                    <Calendar 
                                                        id="tanggalLahir" 
                                                        name='tanggalLahir' 
                                                        {...input} 
                                                        invalid = {isFormFieldValid(meta)}
                                                        disabled={loading}
                                                        dateFormat='dd/mm/yy'
                                                        showIcon
                                                    />
                                                    {getFormErrorMessage(meta)} 
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                                <Field 
                                    name='domisili'
                                    render={({input, meta}) => (
                                        <div className='flex-auto'>
                                            <label htmlFor="domisili" className="font-bold block mb-1">Domicile</label>
                                            <InputText 
                                                id="domisili" 
                                                name='domisili' 
                                                {...input} 
                                                invalid = {isFormFieldValid(meta)}
                                                disabled={loading}
                                                placeholder='Kota / Kabupaten'
                                            />
                                            {getFormErrorMessage(meta)} 
                                        </div>
                                    )}
                                />
                                <Field 
                                    name='pendidikan'
                                    render={({input, meta}) => (
                                        <div className='p-fluid'>
                                            <label htmlFor="pendidikan" className="font-bold block mb-1">Education</label>
                                            <InputText 
                                                id="pendidikan" 
                                                name='pendidikan' 
                                                {...input} 
                                                invalid = {isFormFieldValid(meta)}
                                                disabled={loading}
                                                placeholder='ex: D4 IT PENS'
                                            />
                                            {getFormErrorMessage(meta)} 
                                        </div>
                                    )}
                                />
                                <Button 
                                  label='Next'
                                  size='small'
                                  icon="pi pi-arrow-right"
                                  iconPos='right'
                                  severity='info'
                                  className='w-max mt-2 align-self-end'
                                  loading={loading}
                                  onClick={() => identityValidate(values, {}, true)}
                                />
                            </div>
                        </Card>
                    </StepperPanel>
                    <StepperPanel header='Bank Account'>
                        <Card className='p-fluid ml-2'>
                            <div className='flex flex-column gap-2'>
                                <Field 
                                    name='namaBank'
                                    render={({input, meta}) => (
                                        <div className='flex-auto'>
                                            <label htmlFor="namaBank" className="font-bold block mb-1">Bank Name</label>
                                            <Dropdown 
                                                id="namaBank" 
                                                name='namaBank' 
                                                {...input} 
                                                options={listFinanceAccount}
                                                optionLabel='name'
                                                optionValue='name'
                                                placeholder='choose bank'
                                                filter
                                                style={{maxWidth: '100%'}}
                                                invalid = {isFormFieldValid(meta)}
                                                disabled={loading}
                                            />
                                            {getFormErrorMessage(meta)} 
                                        </div>
                                    )}
                                />
                                <Field 
                                    name='rekening'
                                    render={({input, meta}) => (
                                        <div className='flex-auto'>
                                            <label htmlFor="rekening" className="font-bold block mb-1">Bank Account</label>
                                            <InputText 
                                                id="rekening" 
                                                name='rekening' 
                                                {...input} 
                                                invalid = {isFormFieldValid(meta)}
                                                disabled={loading}
                                            />
                                            {getFormErrorMessage(meta)} 
                                        </div>
                                    )}
                                />
                                <Field 
                                    name='atasNamaRekening'
                                    render={({input, meta}) => (
                                        <div className='flex-auto'>
                                            <label htmlFor="atasNamaRekening" className="font-bold block mb-1">Bank Account Name</label>
                                            <InputText 
                                                id="atasNamaRekening" 
                                                name='atasNamaRekening' 
                                                {...input} 
                                                invalid = {isFormFieldValid(meta)}
                                                disabled={loading}
                                            />
                                            {getFormErrorMessage(meta)} 
                                        </div>
                                    )}
                                />
                                <div className='flex justify-content-between'>
                                    <Button 
                                        label='Back'
                                        size='small'
                                        icon="pi pi-arrow-left"
                                        iconPos='left'
                                        severity='secondary'
                                        className='w-max mt-2 align-self-end'
                                        loading={loading}
                                        onClick={() => stepper.current.prevCallback()}
                                    />
                                    <Button 
                                        label='Next'
                                        size='small'
                                        icon="pi pi-arrow-right"
                                        iconPos='right'
                                        severity='info'
                                        className='w-max mt-2 align-self-end'
                                        loading={loading}
                                        onClick={() => bankValidate(values, {}, true)}
                                    />
                                </div>
                            </div>
                        </Card>
                    </StepperPanel>
                    <StepperPanel header='Set ETP Account'>
                        <Card className='p-fluid ml-5'>
                            <div className='flex flex-column gap-2'>
                                <Field 
                                    name='email'
                                    render={({input, meta}) => (
                                        <div className='flex-auto'>
                                            <label htmlFor="namaBank" className="font-bold block mb-1">Bank Name</label>
                                            <InputText 
                                                id="email" 
                                                name='email' 
                                                {...input} 
                                                placeholder='ex: example@domain.com'
                                                invalid = {isFormFieldValid(meta)}
                                                disabled={loading}
                                                keyfilter="email"
                                            />
                                            {getFormErrorMessage(meta)} 
                                        </div>
                                    )}
                                />
                                <Field 
                                    name='password'
                                    render={({input, meta}) => (
                                        <div className='flex-auto'>
                                            <label htmlFor="password" className="font-bold block mb-1">Password</label>
                                            <Password 
                                                id="password" 
                                                name='password' 
                                                {...input} 
                                                invalid = {isFormFieldValid(meta)}
                                                disabled={loading}
                                                toggleMask
                                            />
                                            {getFormErrorMessage(meta)} 
                                        </div>
                                    )}
                                />
                                <Field 
                                    name='confirmPassword'
                                    render={({input, meta}) => (
                                        <div className='flex-auto'>
                                            <label htmlFor="confirmPassword" className="font-bold block mb-1">Confirm Password</label>
                                            <Password 
                                                id="confirmPassword" 
                                                name='confirmPassword' 
                                                {...input} 
                                                invalid = {isFormFieldValid(meta)}
                                                disabled={loading}
                                                toggleMask
                                            />
                                            {getFormErrorMessage(meta)} 
                                        </div>
                                    )}
                                />
                                <div className='flex justify-content-between mt-2'>
                                    <Button 
                                        label='Back'
                                        type='button'
                                        size='small'
                                        icon="pi pi-arrow-left"
                                        iconPos='left'
                                        severity='secondary'
                                        className='w-max'
                                        loading={loading}
                                        onClick={() => stepper.current.prevCallback()}
                                    />
                                    <Button 
                                        label='Submit'
                                        type='submit'
                                        size='small'
                                        severity='primary'
                                        className='w-max'
                                        loading={loading}
                                    />
                                </div>
                            </div>
                        </Card>
                    </StepperPanel>
                </Stepper>
            </form>
        )}
    />
  )
}

export default FormRegister
