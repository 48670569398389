import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Panel } from 'primereact/panel'
import React, { useState } from 'react'
import { getTimeOnly } from '../../Hooks/helper'
import { Tag } from 'primereact/tag'
import ScheduleForm from './ScheduleForm'
import { deleteData } from '../../Hooks/api'
import { ConfirmDialog } from 'primereact/confirmdialog'

const SchedulePreference = ({preference, loading, setLoading, toast, idSchedule, refreshAction}) => {
  const [ expandRows, setExpandRows ] = useState([]);
  const [ scheduleFormDialog, setScheduleFormDialog ] = useState(false);
  const [ deleteDialog, setDeleteDialog ] = useState(false);
  const [ dataDelete, setDataDelete ] = useState({
    data: {
        scheduleId: idSchedule,
    },
    type: "DAY"
  });

  const rowHeaderTemplate = (data) => (
    <div className='inline-flex w-11 justify-content-between align-items-center ml-2'>
        <Tag value={data.hari} severity="info" style={{verticalAlign: 'middle'}} />   
        <Button icon="pi pi-trash" severity='danger' size='small' onClick={() => showDialogDelete("DAY", data.hari)} />
    </div>
  );

  const actionTableTemplate = (data) => (
    <div className='flex gap-2'>
        <Button icon="pi pi-trash"  size='small' severity='danger' onClick={() => showDialogDelete("SESSION", data._id)}/>
        <Button icon="pi pi-pencil" size='small' outlined severity='info'/>
    </div>
  );

  const deleteSchedule = async () => {
    setLoading(true);

    if (dataDelete.type === "DAY") {
        deleteData(`remove-day-available-schedule`, dataDelete.data)
        .then(() => {
            toast.current.show({
                severity: 'info',
                summary: 'Delete Success',
                detail: 'Preference successfully updated, thanks for your attention.',
                life: 3000
            });

            refreshAction();
            setLoading(false);
        })
        .catch((error) => {
            if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
                toast.current.show({
                    severity: 'error',
                    summary: 'Delete Failed!',
                    detail: error.response.data.message,
                    life: 3000
                });
            }

            setLoading(false);
        })
    }

    if (dataDelete.type === "SESSION") {
        deleteData(`remove-time-available-schedule`, dataDelete.data)
        .then(() => {
            toast.current.show({
                severity: 'info',
                summary: 'Delete Success',
                detail: 'Preference successfully updated, thanks for your attention.',
                life: 3000
            });

            refreshAction();
            setLoading(false);
        })
        .catch((error) => {
            if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
                toast.current.show({
                    severity: 'error',
                    summary: 'Delete Failed!',
                    detail: error.response.data.message,
                    life: 3000
                });
            }
            
            setLoading(false);
        })
    }
  }

  const showDialogDelete = (type, id) => {
    setDeleteDialog(true);
    
    if (type === "DAY") {
        setDataDelete({
            type: type,
            data: {
                scheduleId: idSchedule,
                hari: id
            }
        });
    }

    if (type === "SESSION") {
        setDataDelete({
            type: type,
            data: {
                scheduleId: idSchedule,
                timeId: id
            }
        });
    }
  }
    
  return (
    <Panel header="Schedule Preference" toggleable>
        <ScheduleForm 
          visibility={scheduleFormDialog} 
          setVisibility={() => setScheduleFormDialog(false)} 
          idSchedule={idSchedule} 
          toast={toast}
          refreshAction={refreshAction}
        />

        <ConfirmDialog
            group='declarative'
            visible={deleteDialog}
            onHide={() => setDeleteDialog(false)}
            accept={deleteData}
            reject={() => setDeleteDialog(false)}
            breakpoints={{ '900px': '50vw', '500px': '95vw' }}
            content={() => (
                <div className="flex flex-column align-items-center p-5 surface-overlay border-round">
                    <div className="border-circle bg-red-500 text-white inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                        <i className="pi pi-exclamation-triangle text-5xl"></i>
                    </div>
                    <span className="font-bold text-2xl block mt-2 mb-0">
                        Confirmation
                    </span>
                    <p className="m-0 p-0 text-center">
                        Are you sure to delete this schedule?
                    </p>
                    <div className="flex align-items-center gap-2 mt-4">
                        <Button
                            label="Delete"
                            onClick={() => {
                                setDeleteDialog(false);            
                                deleteSchedule();
                            }}
                            severity='danger'
                            className="w-8rem"
                            size='small'
                        ></Button>
                        <Button
                            label="Cancel"
                            outlined
                            onClick={() => setDeleteDialog(false)}
                            severity='primary'
                            className="w-8rem"
                            size='small'
                            autoFocus
                        ></Button>
                    </div>
                </div>
            )}
        >
        </ConfirmDialog>
        
        <div className='flex justify-content-end mb-3'>
            <Button label='Add New Schedule' icon="pi pi-plus" size='small' className='lg:w-max md:w-max sm:w-max w-full' onClick={() => setScheduleFormDialog(true)}/>
        </div>
        
        <DataTable
          value={preference.jadwal}
          loading={loading}
          rowGroupMode='subheader'
          groupRowsBy='hari'
          expandableRowGroups
          expandedRows={expandRows}
          onRowToggle={(e) => setExpandRows(e.data)}
          rowGroupHeaderTemplate={rowHeaderTemplate}
          className='lg:text-sm md:text-sm sm:text-sm text-xs'
        >
            <Column field='hari' header="Hari" />
            <Column field='jamAwal' header="Jam Mulai" alignHeader="center" align="center" body={(e) => getTimeOnly(e.jamAwal)} />
            <Column field='jamAkhir' header="Jam Selesai" alignHeader="center" align="center" body={(e) => getTimeOnly(e.jamAkhir)} />
            <Column body={actionTableTemplate} />
        </DataTable>
    </Panel>
  )
}

export default SchedulePreference
