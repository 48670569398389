import React from 'react'
import NavBarAuth from '../../Components/NavBarAuth'
import { Outlet } from 'react-router-dom'

const AuthLayouts = () => {
  return (
    <>
      <NavBarAuth />
      <div className='lg:mx-5 md:mx-5 mx-2 lg:px-5 md:px-5 px-2'>
        <Outlet />
      </div>
    </>
  )
}

export default AuthLayouts
