import axios from "axios";
import { accessToken } from "./auth";

const BE_API_URL = "https://be.eduficportal.com";
// const BE_API_URL = "http://127.0.0.1:5000";

export const getData = async (url) => {
  const options = {
    method: 'get',
    url: `${BE_API_URL}/${url}`,
    headers: {
        'Authorization': accessToken,
        'Content-Type': 'application/json'
    }
  }

  const response = await axios.request(options);
  return response;
}

export const postData = async (url, data={}) => {
    const options = {
        method: 'post',
        url: `${BE_API_URL}/${url}`,
        headers: {
            'Authorization': accessToken,
            'Content-Type': 'application/json'
        },
        data: data
    }

    const response = await axios.request(options);
    return response;
} 

export const putData = async (url, data) => {
    const options = {
        method: 'put',
        url: `${BE_API_URL}/${url}`,
        headers: {
            'Authorization': accessToken,
            'Content-Type': 'application/json'
        },
        data: data
    }

    const response = await axios.request(options);
    return response;
}

export const deleteData = async (url, data) => {
    const options = {
        method: 'delete',
        url: `${BE_API_URL}/${url}`,
        headers: {
            'Authorization': accessToken,
            'Content-Type': 'application/json'
        },
        data: data
    }

    const response = await axios.request(options);
    return response;
}