import { Card } from 'primereact/card'
import React from 'react'
import { formattedDateWithDayShort } from '../../Hooks/helper'
import { Tag } from 'primereact/tag'
import { getSeverity } from '../../Constant/general'
import { Link } from 'react-router-dom'

const CardCarousel = ({data}) => {
  return (
    <Link to={`/class-management/${data._id}`}> 
        <Card className='mx-1'>
            <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column justify-content-between align-items-center gap-2 lg:mb-2 md:mb-2 sm_mb-2 mb-3'>
                <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column lg:gap-2 md:gap-2 sm:gap-2'>
                    <h3 className='m-0 p-0 lg:text-lg md:text-lg sm:text-lg text-base lg:text-start md:text-start sm:text-start text-center'>{data.kodeBatch}.{data.kodeKelas} {data.namaKelas}</h3>
                    <h3 className='m-0 p-0 lg:text-lg md:text-lg sm:text-lg text-base lg:text-start md:text-start sm:text-start text-center'>(Lvl {data.level})</h3>
                </div>
                <Tag value={data.jenisKelas} className='h-min' severity={getSeverity(data.jenisKelas, 'CLASS_TYPE')}/>
            </div>
            <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column gap-2 lg:text-left md:text-left sm:text-left text-center justify-content-between align-items-start'>
                <div className='flex flex-column w-full lg:text-left md:text-left sm:text-left text-center lg:text-base md:text-base sm:text-base text-xs'>
                    Jadwal Rilis
                    <p className='lg:text-lg md:text-lg sm:text-base text-xs lg:font-medium md:font-medium sm:font-medium font-bold m-0 p-0'>{formattedDateWithDayShort(data.jadwal)}</p>
                </div>
                <div className='flex flex-column lg:w-min md:w-min sm:w-min w-full text-center'>
                    Siswa
                    <div className='text-2xl font-bold text-primary'>
                        {data.murid.length}
                    </div>
                </div>
            </div>
        </Card>
    </Link>
  )
}

export default CardCarousel
