import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import React from 'react';
import { Link } from 'react-router-dom';

const NavBarMain = ({sidebarVisibility, setSidebarVisibility, teacher}) => {
  return (
    <div className='w-full'>
      <div className='flex justify-content-between align-items-center px-3 py-3 bg-primary w-full'>
        <Button 
          icon="pi pi-bars" 
          outlined 
          className='text-white' 
          size='small'
          onClick={() => setSidebarVisibility(!sidebarVisibility)}
        />
        <Link to="/profile" className='text-white'>
          <div className='flex gap-2 align-items-center cursor-pointer'>
            <h3 className='m-0 p-0'>{teacher}</h3>
            <Avatar 
              icon='pi pi-graduation-cap'
              shape='circle' 
              className='bg-white text-primary'
            />
          </div>
        </Link>
      </div>
    </div>
  )
}

export default NavBarMain
