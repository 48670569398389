import React, { useEffect, useState } from 'react'

const WaitingRoom = () => {
  const [ increment, setIncrement ] = useState(10);

  useEffect(() => {
    setTimeout(() => {
        if (increment > 0) setIncrement(increment - 1);
    }, 1000);
  }, [increment])

  return (
    <div className='flex flex-column justify-content-center align-items-center w-screen h-screen text-center'>
      <img src='../img/loading-image.png' alt='Loading' className='max-w-30rem w-10' />
      <h1 className='lg:text-4xl text-2xl'>Please wait for {increment} second</h1>
    </div>
  )
}

export default WaitingRoom
