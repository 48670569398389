import React from 'react'

const MissingRoom = () => {
  return (
    <div className='flex flex-column justify-content-center align-items-center w-screen h-screen text-center'>
      <img src='../img/locked-room.png' alt='Locked Room' className='max-w-30rem w-10' />
      <h1 className='lg:text-4xl text-2xl'>Oops, the room is not currently open!</h1>
    </div>
  )
}

export default MissingRoom
