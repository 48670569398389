import { Card } from 'primereact/card';
import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Button } from 'primereact/button';
import { formatMonth } from '../../Hooks/helper';
import { getData } from '../../Hooks/api';
import { Toast } from 'primereact/toast';
import { useOutletContext } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import DetailSchedule from './DetailSchedule';

const MySchedule = () => {
  const {teacher} = useOutletContext();
  const toast = useRef(null);
  const fullCalendar = useRef(null);
  const [ scheduleFilter, setScheduleFilter ] = useState('ALL');
  
  // Data Handler
  const [ calendarTitle, setCalendarTitle ] = useState(formatMonth(new Date()));
  const [ listEvent, setListEvent ] = useState([]);
  const [ listEventFiltered, setListEventFiltered ] = useState([]);
  const [ listDataSchedule, setListDataSchedule ] = useState([]);

  // Dialog Handler 
  const [ detailScheduleDialog, setDetailScheduleDialog ] = useState(false);

  const getListEvent = async () => {
    getData(`teacher/calender/${teacher._id}`)
    .then((response) => {
        setListEvent(response.data);
        filterEvent(response.data);
    })
    .catch((error) => {
      if (error.response && !error.response.status === 403 && !error.response.data.message === "Unauthorized: Invalid token") {
        toast.current.show({
          severity: 'error',
          summary: 'Load Failed!',
          detail: error.response.data.message,
          life: 3000
        }); 
      }
    });
  }

  useEffect(() => {
    if (teacher._id) {
      getListEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teacher]);

  useEffect(() => {
    filterEvent(listEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleFilter]);
  
  // Click Handler
  const handleDateClick = (info) => {
    let date = info.dateStr;

    const listDateFiltered = listEventFiltered.filter((event) => {
        const eventDate = event.start.split("T")[0]; // Extract date portion of event
        return eventDate === date;
    });

    setListDataSchedule(listDateFiltered);
    setDetailScheduleDialog(true);
  };

  // full calendar header handler
  const handlePrev = () => {
    const calendarApi = fullCalendar.current.getApi();
    calendarApi.prev();
    updateTitle(calendarApi);
  };
    
  const handleNext = () => {
    const calendarApi = fullCalendar.current.getApi();
    calendarApi.next();
    updateTitle(calendarApi);
  };
    
  const handleToday = () => {
    const calendarApi = fullCalendar.current.getApi();
    calendarApi.today();
    updateTitle(calendarApi);
  };

  const updateTitle = (calendarApi) => {
    const currentView = calendarApi.view;
    const newTitle = currentView.title;
        
    setCalendarTitle(newTitle)
  }

  const filterEvent = (data) => {
    if (scheduleFilter === 'ALL') setListEventFiltered(data) 
    if (scheduleFilter === 'PTC') setListEventFiltered(listEvent.filter((eduEvent) => eduEvent.data.status === "PTC"));
    if (scheduleFilter === 'SESSION') setListEventFiltered(listEvent.filter((eduEvent) => eduEvent.data.status !== "PTC"));
  }

  return (
    <Card>
        <Toast ref={toast} />

        <DetailSchedule 
            visible={detailScheduleDialog}
            visibleHandler={() => { 
               setDetailScheduleDialog(false);
               setListDataSchedule([]);
            }}
            dataSchedule={listDataSchedule}
        />

        <div className='flex lg:flex-row md:flex-row sm:flex-row lg:justify-content-between md:justify-content-between sm:justify-content-between lg:align-items-center md:align-items-center sm:align-items-center flex-column gap-1 w-full mb-3'>
            <h1 className='m-0 p-0 text-center text-3xl'>{calendarTitle}</h1>
            <div className='flex gap-1'>
                <Button label='Today' size='small' outlined onClick={handleToday} className='lg:w-max md:w-max sm:w-max w-full lg:px-2 md:px-2 sm:px-2 px-0 '/>
                <Dropdown
                    id='scheduleFilter'
                    name='scheduleFilter'
                    value={scheduleFilter}
                    options={['ALL', 'PTC', 'SESSION']}
                    placeholder='filter schedule'
                    onChange={(e) => setScheduleFilter(e.target.value)}
                />

                {/* Full Label  */}
                <Button icon="pi pi-angle-left" label='Prev' onClick={handlePrev} severity='secondary' size='small' className='lg:flex md:flex sm:flex hidden' />
                <Button icon="pi pi-angle-right" label='Next' iconPos='right' onClick={handleNext} severity='secondary' size='small' className='lg:flex md:flex sm:flex hidden'/>
                
                {/* No Label */}
                <Button icon="pi pi-angle-left" onClick={handlePrev} severity='secondary' size='small' className='lg:hidden md:hidden sm:hidden flex' />
                <Button icon="pi pi-angle-right" onClick={handleNext} severity='secondary' size='small' className='lg:hidden md:hidden sm:hidden flex'/>
            </div>
        </div>

        <FullCalendar
            ref={fullCalendar}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            headerToolbar={false}
            events={listEventFiltered}
            locale="en-GB"
            eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                meridiem: false
            }}
            dayCellDidMount={(e) => {
                let today = new Date();
                if (
                    e.date.getFullYear() === today.getFullYear() &&
                    e.date.getDate() === today.getDate() &&
                    e.date.getMonth() === today.getMonth()
                ) e.el.style.backgroundColor = '#86ffa2';
            }}
            eventDidMount={(e) => {
                if (e.event.extendedProps.data.status === "PTC") {
                    e.el.style.backgroundColor = "#28a745";
                    e.el.style.color = "#ffffff";
                    e.el.style.paddingLeft= '1px';

                    let dotElement = e.el.querySelector(".fc-daygrid-event-dot");
                    if (dotElement) {
                        dotElement.style.display = "none";
                    }
                } else {
                    // e.el.style.backgroundColor = "#28a745";
                    let dotElement = e.el.querySelector(".fc-daygrid-event-dot");
                    if (dotElement) {
                        dotElement.style.borderColor = '#28a745';
                    }
                }
            }}
            dateClick={handleDateClick}
            height="auto"
            selectable
        />
    </Card>
  );
}

export default MySchedule
