import axios from "axios";
import { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

export let accessToken = localStorage.getItem('accessToken');

export const ProtectedPages = () => {
    const navigate = useNavigate();

    const SetupAxiosInterceptors = () => {
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response && error.response.status === 403 && error.response.data.message === "Unauthorized: Invalid token") {
                    localStorage.removeItem('accessToken');
                    navigate('/auth/login');
                }
                return Promise.reject(error);
            }
        )
    }

    accessToken = localStorage.getItem('accessToken');

    useEffect(() => {
        SetupAxiosInterceptors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return accessToken ? <Outlet /> : <Navigate to='/auth/login' replace />
}

export const PublicPages = () => {
    const accessToken = localStorage.getItem('accessToken');
    return accessToken ? <Navigate to='/' replace /> : <Outlet />
}