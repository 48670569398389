import { Link, useOutletContext } from 'react-router-dom';
import DashJumbotron from './DashJumbotron';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { useEffect, useRef, useState } from 'react';
import { getData, postData } from '../../Hooks/api';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { formattedDateWithDay } from '../../Hooks/helper';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Carousel } from 'primereact/carousel';
import CardCarousel from './CardCarousel';

const Dashboard = () => {
  const {teacher} = useOutletContext();
  const toast = useRef(null);
  const [ loadingTroubleClass, setLoadingTroubleClass ] = useState(false);
  const [ listTroubleClass, setListTroubleClass ] = useState([]);
  const [ listClassToday, setListClassToday ] = useState([]);
  const breakPointList = [
    {
      breakpoint: '1400px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '1000px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '900px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '670px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '405px',
      numVisible: 1,
      numScroll: 1
    }
  ]
  
  // Filter Primereact
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const getTroubleClass = async () => {
    setLoadingTroubleClass(true);
    
    getData(`check-delay-class/${teacher._id}`)
    .then((response) => {
      setLoadingTroubleClass(false);
      setListTroubleClass(response.data.delayedClass);
    })
    .catch((error) => {
      setLoadingTroubleClass(false);
      if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
        toast.current.show({
          severity: 'error',
          summary: 'Load Failed!',
          detail: error.response.data.message,
          life: 3000
        });
      }
    })
  }

  const getClassToday = async () => {
    postData(`today-class-teacher/${teacher._id}`)
    .then((response) => {
      setListClassToday(response.data);
    })
    .catch((error) => {
      if (error.status !== 404) {
        toast.current.show({
          severity: 'error',
          summary: 'Load Failed!',
          detail: error.response.data.message,
          life: 3000
        });
      }
    })
  }

  useEffect(() => {
    if (teacher._id) { 
      getTroubleClass();
      getClassToday();    
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teacher])

  const headerTemplate = () => (
    <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column justify-content-between gap-2'>
      <div className='flex lg:justify-content-start md:justify-content-start sm:justify-content-start justify-content-between gap-1 align-items-center text-red-500'>
        <h3 className='m-0 p-0'>Kelas Belum Diabsen</h3>
        <i className='pi pi-exclamation-circle text-lg tooltip-delayed'></i>
        <Tooltip target=".tooltip-delayed" hideDelay={2000} content="Kelas yang belum atau telat diabsen" position='top' />
      </div>
      <IconField iconPosition="left">
        <InputIcon className="pi pi-search"> </InputIcon>
        <InputText 
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Search" 
          className='w-full' 
        />
      </IconField>
    </div>
  )

  const actionTemplate = (data) => (
    <Link to={`/class-management/${data.kelas}`}>
      <Button label='Detail' severity='info' size='small'/>
    </Link>
  )
 
  return (
    <>
      <Toast ref={toast}/>
      <div className='flex flex-column gap-3'>
        <DashJumbotron nickName={teacher.panggilan} />
        <Card>
          <DataTable 
            value={listTroubleClass}
            removableSort
            header={headerTemplate}
            loading={loadingTroubleClass}
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
            filters={filters}
            globalFilterFields={['namaKelas', 'daftarSesi.sesi', 'daftarSesi.jadwal']}
            className='lg:text-sm md:text-sm sm:text-sm text-xs'
          >
            <Column header="No" body={(data, Options) => Options.rowIndex + 1} />
            <Column field='namaKelas' header="Nama Kelas" sortable/>
            <Column field='daftarSesi.sesi' header="Sesi" sortable/>
            <Column field='daftarSesi.jadwal' header="Jadwal Sesi" body={(e) => formattedDateWithDay(e.daftarSesi.jadwal)} sortable/>
            <Column body={actionTemplate}/>
          </DataTable>
        </Card>
        <h2 className='m-0 p-0 text-center text-primary'>Today Activity</h2>
        { listClassToday.length > 0 ? <Carousel
          value={listClassToday}
          // autoplayInterval={3000}
          numVisible={3}
          responsiveOptions={breakPointList}
          itemTemplate={(e) => <CardCarousel data={e}/>}
        /> : <div className='flex flex-column align-items-center'>
          <img src="../img/not-found-activity.png" alt='No Activity' className='max-w-20rem w-8' />
          <h2 className='m-1 text-center'>No Activity Today</h2>
        </div>}
      </div> 
    </>
  )
}

export default Dashboard