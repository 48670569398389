export const checkDiffObject = (obj1, obj2) => {
    let status = false;
    const key = Object.keys(obj1);

    key.forEach(keyObj => {    
        if (JSON.stringify(obj1[keyObj]) !== JSON.stringify(obj2[keyObj])) status = true;
    });

    return status;
}

export const formattedDateWithDay = (date) => {
    const newDate = new Date(date)
    const formattedDate = newDate.toLocaleString("id-ID", {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
    })

    const formattedTime = newDate.toLocaleTimeString('it-IT', { hour: "2-digit", minute: "2-digit" })

    const fullDate = `${formattedDate} (${formattedTime})`

    return fullDate
}

export const formattedDateWithDayShort = (date) => {
    const newDate = new Date(date)
    const formattedDate = newDate.toLocaleString("id-ID", {
        weekday: "long",
        day: "numeric",
        month: "short",
        year: "numeric",
    })

    const formattedTime = newDate.toLocaleTimeString('it-IT', { hour: "2-digit", minute: "2-digit" })

    const fullDate = `${formattedDate} (${formattedTime})`

    return fullDate
}

export const formattedDate = (date) => { 
    const newDate = new Date(date)
    const formattedDate = newDate.toLocaleString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
    })

    const formattedTime = newDate.toLocaleTimeString('it-IT', { hour: "2-digit", minute: "2-digit" })

    const fullDate = formattedDate + " - " + formattedTime

    return fullDate
}

export const formattedDateWithTime = (date) => { 
    const newDate = new Date(date)
    const formattedDate = newDate.toLocaleString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
    })

    const formattedTime = newDate.toLocaleTimeString('it-IT', { hour: "2-digit", minute: "2-digit" })

    const fullDate = `${formattedDate} (${formattedTime})`

    return fullDate
}

export const formattedDateWithTimeShort = (date) => { 
    const newDate = new Date(date)
    const formattedDate = newDate.toLocaleString("id-ID", {
        day: "numeric",
        month: "short",
        year: "numeric",
    })

    const formattedTime = newDate.toLocaleTimeString('it-IT', { hour: "2-digit", minute: "2-digit" })

    const fullDate = `${formattedDate} (${formattedTime})`

    return fullDate
}

export const formattedDateWithOutTime = (date) => { 
    const newDate = new Date(date)
    const formattedDate = newDate.toLocaleString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
    })

    const fullDate = formattedDate
    return fullDate
}

export const getTimeOnly = (date) => {
    const newDate = new Date(date);
    const formattedTime = newDate.toLocaleTimeString('it-IT', { hour: "2-digit", minute: "2-digit" });
    
    return formattedTime;
}

export const formatMonth = (stringDate) => {
    const newDate = new Date(stringDate)
    const formattedDate = newDate.toLocaleString("id-ID", {
        month: "long",
        year: "numeric",
    })

    const fullDate = formattedDate
    return fullDate
}

export const formatIDRCurrency = (data) => {
    const newData = data.toLocaleString("id-ID", {style:"currency", currency:"IDR"})
    return newData
}

export const formatAccounting = (data) => {
    const newData = data.toLocaleString("id-ID", {style:"currency", currency:"IDR"})
    return (newData.slice(2))
}

export const copyLink = (text, toast) => {
  navigator.clipboard.writeText(text);

  toast.current.show({
    severity: 'info',
    summary: 'Copied!',
    detail: "Success copied content to clipboard",
    life: 3000
  });
}