import React, { useEffect, useState } from 'react'
import SideBarMain from '../../Components/SideBar'
import NavBarMain from '../../Components/NavBarMain';
import { Outlet } from 'react-router-dom';
import { getData } from '../../Hooks/api';
import MainFooter from '../../Components/Footer';

const MainLayouts = () => {
  const [ sidebarVisibility, setSidebarVisibility ] = useState(true);
  const [ visibility, setVisibility ] = useState(false);
  const id = localStorage.getItem('teacherId');
  const [ teacher, setTeacher ] = useState({});
  
  const getTeacher = async () => {
    getData(`teachers/${id}`)
    .then((response) => {
      setTeacher(response.data);
    })
    .catch((error) => {
      console.log(error);
    })
  }

  useEffect(() => {
    getTeacher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickBarButton = () => {
    setSidebarVisibility(!sidebarVisibility);
    if (window.innerWidth <= 1000) setVisibility(!visibility);  
  }
  
  return (
    <div className='flex w-100'>
      <SideBarMain sidebarVisibility={sidebarVisibility} visibility={visibility} setVisibility={setVisibility} />
      <div className='edu-main w-full h-screen overflow-y-auto'>
        <div className='flex flex-column h-full'>
          <NavBarMain sidebarVisibility={sidebarVisibility} setSidebarVisibility={onClickBarButton} teacher={teacher.panggilan}/>
          <div className='lg:p-4 md:p-4 p-3'>
              <Outlet context={{teacher, setTeacher}}/>
          </div>
          <MainFooter />
        </div>
      </div>
    </div>
  )
}

export default MainLayouts
