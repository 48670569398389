import { Panel } from 'primereact/panel'
import React, { useEffect, useRef, useState } from 'react'
import { getData } from '../../Hooks/api'
import { Toast } from 'primereact/toast';
import { Link, useOutletContext } from 'react-router-dom';
import { TabPanel, TabView } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';
import { formattedDate } from '../../Hooks/helper';
import { Tag } from 'primereact/tag';
import { getSeverity } from '../../Constant/general';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import FormAddUpdateClass from './FormAddUpdateClass';

const ClassManagement = () => {
  const toast = useRef(null);
  const {teacher} = useOutletContext();
  const [ titleDialog, setTitleDialog ] = useState(""); 
  const [ loading, setLoading ] = useState(false);
  const [ addUpdateDialog, setAddUpdateDialog ] = useState(false);
  const [ classDetail, setClassDetail ] = useState({
    _id:null,
    kodeKelas: '',
    kodeBatch: '',
    namaKelas: '',
    jadwal: null,
    jenisKelas: '',
    materiKelas: '',
    level: '',
    noteSubjek: '',
    guru: ""
  })
  const [ activeTab, setActiveTab ] = useState(0);
  const [ listClass, setListClass ] = useState({
    active: [],
    needPTC: [],
    needRR: [],
    needEnd: [],
    finish: []
  });
  
  // Filter Primereact
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const getListClass = async () => {
    setLoading(true);

    getData(`teacher-class/${teacher._id}`)
    .then((response) => {
        const classes = response.data;
        
        classes.map((kelas) => {
            kelas.subject= `${kelas.kodeBatch}.${kelas.kodeKelas} ${kelas.namaKelas}`;
            return kelas;
        });

        setListClass({
            ...listClass,
            active: classes.filter(kelas => (kelas.statusKelas === "Aktif" || kelas.statusKelas === "Belum terverifikasi")),
            needPTC: classes.filter(kelas => kelas.statusKelas === "Belum PTC"),
            needRR: classes.filter(kelas => kelas.statusKelas === "Belum RR"),
            needEnd: classes.filter(kelas => kelas.statusKelas === "Belum Diselesaikan"),
            finish: classes.filter(kelas => kelas.statusKelas === "Selesai")
        });

        setLoading(false);
    })
    .catch((error) => {
      if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
        toast.current.show({
          severity: 'error',
          summary: 'Load Failed!',
          detail: error.response.data.message,
          life: 3000
        });
      }
      setLoading(false);
    })
  }

  useEffect(() => {
    if (teacher._id) { 
        getListClass();
        setClassDetail({
            ...classDetail,
            guru: teacher._id
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teacher]);

  const headerTableTemplate = () => (
    <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column justify-content-between align-items-center gap-2'>
      <IconField iconPosition="left" className='lg:w-max md:w-max sm-w-max w-full'>
        <InputIcon className="pi pi-search"> </InputIcon>
        <InputText 
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Search" 
          className='w-full' 
        />
      </IconField>
      <Button 
        label='Add Class' 
        icon="pi pi-plus" 
        size='small' 
        className='lg:w-max md:w-max sm-w-max w-full' 
        onClick={() => showDialog(null, "ADD CLASS")}
      />
    </div>
  )

  const classTypeTemplate = (data) => (
    <Tag value={data} severity={getSeverity(data, "CLASS_TYPE")} />
  )

  const showDialog = (data, title) => {
    if (data && data !== null) {
        setClassDetail({
            _id: data._id,
            kodeKelas: data.kodeKelas,
            kodeBatch: data.kodeBatch,
            namaKelas: data.namaKelas,
            jadwal: new Date(data.jadwal),
            jenisKelas: data.jenisKelas,
            materiKelas: data.materiKelas,
            level: data.level,
            noteSubjek: data.noteSubjek
        });

        setTitleDialog(title);
    }  else {
        setClassDetail({
            ...classDetail,
            jadwal: new Date()
        });

        console.log(classDetail);
        

        setTitleDialog(title);
    }
    
    setAddUpdateDialog(true);
  }

  const hideDialog = () => {
    setClassDetail({
      _id:null,
      kodeKelas: '',
      kodeBatch: '',
      namaKelas: '',
      jadwal: null,
      jenisKelas: '',
      materiKelas: '',
      level: '',
      noteSubjek: '',
      guru: teacher._id
    });

    setAddUpdateDialog(false);
  };

  const actionTemplate = (data) => (
    <div className='flex gap-1'>
        { (data.statusKelas === "Aktif") && <Button 
          icon="pi pi-pencil" 
          outlined size='small' 
          severity='success' 
          onClick={() => showDialog(data, "EDIT CLASS")}
        /> }
        <Link to={data.statusKelas === "Belum terverifikasi" ? "#" : `/class-management/${data._id}`}>
            <Button 
                icon="pi pi-eye" 
                size='small' 
                severity='info' 
                tooltip={data.statusKelas === "Belum terverifikasi"? "Menunggu verifikasi" : false}
                tooltipOptions={{hideDelay: 1000, position: 'left'}}
            />
        </Link>
    </div>
  )

  return (
    <div className='flex flex-column gap-3'>
      <Toast ref={toast} />
      <FormAddUpdateClass
        visiblity={addUpdateDialog}
        setVisibility={() => hideDialog()}
        account={teacher}
        data= {classDetail}
        titleDialog={titleDialog}
        toast={toast}
        refreshAction={getListClass}
        loading={loading}
        setLoading={setLoading}
      />
      <Panel header="Overview" toggleable>
        <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column justify-content-between align-items-center lg:px-3 md:px-3 sm:px-3 gap-3 lg:text-left md:text-left sm:text-left text-center'>
            <div>
                <div className='flex align-items-center gap-2'>
                    <h4 className='m-0 p-0'>Kelas Aktif</h4>
                    <i className='pi pi-eye text-xl text-blue-400 cursor-pointer' onClick={() => setActiveTab(0)}></i>
                </div>
                <h2 className='m-0 p-0'>{listClass.active.length}<span className='text-primary'> Kelas</span></h2>
            </div>
            <div>
                <div className='flex align-items-center gap-2'>
                    <h4 className='m-0 p-0'>Kelas Belum PTC</h4>
                    <i className='pi pi-eye text-xl text-blue-400 cursor-pointer' onClick={() => setActiveTab(1)}></i>
                </div>
                <h2 className='m-0 p-0'>{listClass.needPTC.length}<span className='text-primary'> Kelas</span></h2>
            </div>
            <div>
                <div className='flex align-items-center gap-2'>
                    <h4 className='m-0 p-0'>Kelas Belum RR</h4>
                    <i className='pi pi-eye text-xl text-blue-400 cursor-pointer' onClick={() => setActiveTab(2)}></i>
                </div>
                <h2 className='m-0 p-0'>{listClass.needRR.length}<span className='text-primary'> Kelas</span></h2>
            </div>
            <div>
                <div className='flex align-items-center gap-2'>
                <h4 className='m-0 p-0'>Kelas Belum Diselesaikan</h4>
                    <i className='pi pi-eye text-xl text-blue-400 cursor-pointer' onClick={() => setActiveTab(3)}></i>
                </div>
                <h2 className='m-0 p-0'>{listClass.needEnd.length}<span className='text-primary'> Kelas</span></h2>
            </div>
            <div>
                <div className='flex align-items-center gap-2'>
                <h4 className='m-0 p-0'>Kelas Selesai</h4>
                    <i className='pi pi-eye text-xl text-blue-400 cursor-pointer' onClick={() => setActiveTab(4)}></i>
                </div>
                <h2 className='m-0 p-0'>{listClass.finish.length}<span className='text-primary'> Kelas</span></h2>
            </div>
        </div>
      </Panel>
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="Kelas Aktif">
            <DataTable
                value={listClass.active}
                removableSort
                header={headerTableTemplate}
                loading={loading}
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 25, 50]}
                filters={filters}
                globalFilterFields={['subject', 'level', 'materiKelas', 'jadwal', 'jumlahSiswa', 'jenisKelas']}
                className='lg:text-sm md:text-sm sm:text-sm text-xs'
            >
                <Column body={(data, Option) => Option.rowIndex + 1}/>
                <Column field='subject' header="Subjek" sortable />
                <Column field='level' header="Level" sortable alignHeader="center" align="center" />
                <Column field='materiKelas' header="Level Materi" sortable alignHeader="center" align="center" />
                <Column field='jadwal' header="Jadwal Mulai" body={(e) => formattedDate(e.jadwal)} sortable  align="center" />
                <Column field='jenisKelas' header="Tipe Kelas" body={(e) => classTypeTemplate(e.jenisKelas)} sortable />
                <Column field='jumlahSiswa' header="Siswa" body={(e) => `${e.jumlahSiswa} Anak`} sortable align="center" alignHeader="center" />
                <Column body={(e) => actionTemplate(e)}/>
            </DataTable>
        </TabPanel>
        <TabPanel header="Belum PTC">
        <DataTable
                value={listClass.needPTC}
                removableSort
                header={headerTableTemplate}
                loading={loading}
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 25, 50]}
                filters={filters}
                globalFilterFields={['subject', 'level', 'materiKelas', 'jadwal', 'jumlahSiswa', 'jenisKelas']}
                className='lg:text-sm md:text-sm sm:text-sm text-xs'
            >
                <Column body={(data, Option) => Option.rowIndex + 1}/>
                <Column field='subject' header="Subjek" sortable />
                <Column field='level' header="Level" sortable alignHeader="center" align="center" />
                <Column field='materiKelas' header="Level Materi" sortable alignHeader="center" align="center" />
                <Column field='jadwal' header="Jadwal Mulai" body={(e) => formattedDate(e.jadwal)} sortable  align="center" />
                <Column field='jenisKelas' header="Tipe Kelas" body={(e) => classTypeTemplate(e.jenisKelas)} sortable />
                <Column field='jumlahSiswa' header="Siswa" body={(e) => `${e.jumlahSiswa} Anak`} sortable align="center" alignHeader="center" />
                <Column body={(e) => actionTemplate(e)}/>
            </DataTable>
        </TabPanel>
        <TabPanel header="Belum RR">
        <DataTable
                value={listClass.needRR}
                removableSort
                header={headerTableTemplate}
                loading={loading}
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 25, 50]}
                filters={filters}
                globalFilterFields={['subject', 'level', 'materiKelas', 'jadwal', 'jumlahSiswa', 'jenisKelas']}
                className='lg:text-sm md:text-sm sm:text-sm text-xs'
            >
                <Column body={(data, Option) => Option.rowIndex + 1}/>
                <Column field='subject' header="Subjek" sortable />
                <Column field='level' header="Level" sortable alignHeader="center" align="center" />
                <Column field='materiKelas' header="Level Materi" sortable alignHeader="center" align="center" />
                <Column field='jadwal' header="Jadwal Mulai" body={(e) => formattedDate(e.jadwal)} sortable  align="center" />
                <Column field='jenisKelas' header="Tipe Kelas" body={(e) => classTypeTemplate(e.jenisKelas)} sortable />
                <Column field='jumlahSiswa' header="Siswa" body={(e) => `${e.jumlahSiswa} Anak`} sortable align="center" alignHeader="center" />
                <Column body={(e) => actionTemplate(e)}/>
            </DataTable>
        </TabPanel>
        <TabPanel header="Belum Diselesaikan">
        <DataTable
                value={listClass.needEnd}
                removableSort
                header={headerTableTemplate}
                loading={loading}
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 25, 50]}
                filters={filters}
                globalFilterFields={['subject', 'level', 'materiKelas', 'jadwal', 'jumlahSiswa', 'jenisKelas']}
                className='lg:text-sm md:text-sm sm:text-sm text-xs'
            >
                <Column body={(data, Option) => Option.rowIndex + 1}/>
                <Column field='subject' header="Subjek" sortable />
                <Column field='level' header="Level" sortable alignHeader="center" align="center" />
                <Column field='materiKelas' header="Level Materi" sortable alignHeader="center" align="center" />
                <Column field='jadwal' header="Jadwal Mulai" body={(e) => formattedDate(e.jadwal)} sortable  align="center" />
                <Column field='jenisKelas' header="Tipe Kelas" body={(e) => classTypeTemplate(e.jenisKelas)} sortable />
                <Column field='jumlahSiswa' header="Siswa" body={(e) => `${e.jumlahSiswa} Anak`} sortable align="center" alignHeader="center" />
                <Column body={(e) => actionTemplate(e)}/>
            </DataTable>
        </TabPanel>
        <TabPanel header="Selesai">
        <DataTable
                value={listClass.finish}
                removableSort
                header={headerTableTemplate}
                loading={loading}
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 25, 50]}
                filters={filters}
                globalFilterFields={['subject', 'level', 'materiKelas', 'jadwal', 'jumlahSiswa', 'jenisKelas']}
                className='lg:text-sm md:text-sm sm:text-sm text-xs'
            >
                <Column body={(data, Option) => Option.rowIndex + 1}/>
                <Column field='subject' header="Subjek" sortable />
                <Column field='level' header="Level" sortable alignHeader="center" align="center" />
                <Column field='materiKelas' header="Level Materi" sortable alignHeader="center" align="center" />
                <Column field='jadwal' header="Jadwal Mulai" body={(e) => formattedDate(e.jadwal)} sortable  align="center" />
                <Column field='jenisKelas' header="Tipe Kelas" body={(e) => classTypeTemplate(e.jenisKelas)} sortable />
                <Column field='jumlahSiswa' header="Siswa" body={(e) => `${e.jumlahSiswa} Anak`} sortable align="center" alignHeader="center" />
                <Column body={(e) => actionTemplate(e)}/>
            </DataTable>
        </TabPanel>
      </TabView>
    </div>
  )
}

export default ClassManagement
