import { Sidebar } from 'primereact/sidebar';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';

const SideBarMain = ({sidebarVisibility, visibility, setVisibility}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentLocation = location.pathname.split('/')[1];
  
  const logOut = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('teacherId');
    navigate('/auth/login');
  }


  return (
    <>
      <div className={`card flex justify-content-center ${sidebarVisibility ? 'edu-sidebar' : 'edu-sidebar-hidden'}`}>
        <div className="surface-section h-screen block flex-shrink-0 absolute lg:static left-0 top-0 border-right-1 surface-border select-none w-full">
          <div className="flex flex-column h-full">
            <div className="flex align-items-center justify-content-between px-4 pt-3 flex-shrink-0">
              <span className="inline-flex align-items-center justify-content-center">
                <img src='../img/edufic-logo.png' alt='Logo Edufic' className='w-9'/>
              </span>
            </div>
            <div className="overflow-y-auto">
              <ul className="list-none px-2 py-2 m-0">
                <li>
                    <Link to='/'>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 transition-duration-150 transition-colors w-full ${(currentLocation === "" || currentLocation === "profile") ? "bg-primary text-white hover:bg-green-300" : "text-color-secondary hover:surface-100"}`}>
                            <i className="pi pi-home mr-2"></i>
                            <span className="font-medium">Dashboard</span>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to='/class-management'>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "class-management" ? "bg-primary text-white hover:bg-green-300" : "text-color-secondary hover:surface-100"}`}>
                            <i className="pi pi-sitemap mr-2"></i>
                            <span className="font-medium">Class Management</span>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to='/revenue'>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "revenue" ? "bg-primary text-white hover:bg-green-300" : "text-color-secondary hover:surface-100"}`}>
                            <i className="pi pi-dollar mr-2"></i>
                            <span className="font-medium">Revenue</span>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to='/my-schedule'>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "my-schedule" ? "bg-primary text-white hover:bg-green-300" : "text-color-secondary hover:surface-100"}`}>
                            <i className="pi pi-calendar mr-2"></i>
                            <span className="font-medium">My Schedule</span>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to='/preference'>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "preference" ? "bg-primary text-white hover:bg-green-300" : "text-color-secondary hover:surface-100"}`}>
                            <i className="pi pi-sliders-h mr-2"></i>
                            <span className="font-medium">Preference</span>
                        </div>
                    </Link>
                </li>
              </ul>
            </div>
            <div className="mt-auto overflow-hidden">
              <hr className="mb-3 mx-3 border-top-1 border-none surface-border" />
              <div className='p-3'>
                <Button label='Logout' severity='danger' size='small' className='w-full' onClick={() => logOut()}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sidebar visible={visibility} onHide={() => setVisibility(false)} content={
        <div className="surface-section h-screen block flex-shrink-0 absolute lg:static left-0 top-0 border-right-1 surface-border select-none">
          <div className="flex flex-column h-full">
            <div className="flex align-items-center justify-content-between px-3 pt-3 flex-shrink-0">
              <span className="inline-flex align-items-center justify-content-between">
                <img src='../img/edufic-logo.png' alt='Logo Edufic' className='w-8'/>
                <i className="pi pi-times border-circle" onClick={() => setVisibility(false)}/>
              </span>
            </div>
            <div className="overflow-y-auto">
              <ul className="list-none px-2 py-2 m-0">
                <li>
                    <Link to='/' onClick={() => setVisibility(false)}>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${(currentLocation === "" || currentLocation === "profile") ? "bg-primary text-white hover:bg-green-300" : "text-color-secondary hover:surface-100"}`}>
                            <i className="pi pi-home mr-2"></i>
                            <span className="font-medium">Dashboard</span>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to='/class-management' onClick={() => setVisibility(false)}>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "class-management" ? "bg-primary text-white hover:bg-green-300" : "text-color-secondary hover:surface-100"}`}>
                            <i className="pi pi-sitemap mr-2"></i>
                            <span className="font-medium">Class Management</span>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to='/revenue' onClick={() => setVisibility(false)}>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "revenue" ? "bg-primary text-white hover:bg-green-300" : "text-color-secondary hover:surface-100"}`}>
                            <i className="pi pi-dollar mr-2"></i>
                            <span className="font-medium">Revenue</span>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to='/my-schedule' onClick={() => setVisibility(false)} >
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "my-schedule" ? "bg-primary text-white hover:bg-green-300" : "text-color-secondary hover:surface-100"}`}>
                            <i className="pi pi-calendar mr-2"></i>
                            <span className="font-medium">My Schedule</span>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to='/preference' onClick={() => setVisibility(false)}>
                        <div className={`flex align-items-center cursor-pointer px-3 py-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${currentLocation === "preference" ? "bg-primary text-white hover:bg-green-300" : "text-color-secondary hover:surface-100"}`}>
                            <i className="pi pi-sliders-h mr-2"></i>
                            <span className="font-medium">Preference</span>
                        </div>
                    </Link>
                </li>
              </ul>
            </div>
            <div className="mt-auto overflow-wrap mb-5">
              <hr className="mb-3 mx-3 border-top-1 border-none surface-border" />
              <div className='p-3 mb-5'>
                <Button label='Logout' severity='danger' size='small' className='w-full' onClick={() => logOut()}/>
              </div>
            </div>
          </div>
        </div>
      }></Sidebar>
    </>
  )
}

export default SideBarMain
