import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getData } from '../../../Hooks/api';
import MissingRoom from '../../NotFound/MissingRoom';
import WaitingRoom from '../../Loading';

const CheckRoom = () => {
  const {id} = useParams();
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    getData(`rooms/class/${id}`)
    .then((response) => {
      window.location.replace(response.data);
    })
    .catch((error) => {
        console.log(error);
        setLoading(false);
    });
  }, [id]);

  return (
    loading ?  <WaitingRoom /> : <MissingRoom />
  )
}

export default CheckRoom
