import { Button } from 'primereact/button'
import { Panel } from 'primereact/panel'
import { Password } from 'primereact/password'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { postData } from '../../Hooks/api'
import { Dialog } from 'primereact/dialog'
import { useNavigate } from 'react-router-dom'

const ResertPassword = ({
    teacher,
    toast
}) => {
  const navigate = useNavigate();
  const [ coolDown, setCoolDown ] = useState(5);
  const [ coolDownDialog, setCoolDownDialog ] = useState(false);

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    email: teacher.email
  }
  
  const validateResetPassword = (data) => {
    let errors = {};

    if (!data.oldPassword) errors.oldPassword = "Old Password is required";
    if (!data.newPassword) errors.newPassword = "New Password is required";
    if (!data.confirmPassword) errors.confirmPassword = "Confirm Password is required";
    if (data.newPassword !== data.confirmPassword) errors.confirmPassword = "Confirm password doesn't match";

    return errors;
  }

  const resetPassword = (data, form) => {
    postData(`teachers/${teacher._id}/reset-password`, data)
    .then(() => {
        form.restart();
        setCoolDownDialog(true);
    })
    .catch((error) => {
        if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
            toast.current.show({
                severity: 'error',
                summary: 'Update Failed!',
                detail: error.response.data.message,
                life: 3000
            });
        }
    });
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);

  const getFormErrorMessage = (meta) => {  
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    if (parseInt(coolDown) > 0 && coolDownDialog) {  
        setTimeout(() =>{
            setCoolDown(parseInt(coolDown) - 1);
        }, 1000);
    } 
    if (coolDown === 0) {
        logOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coolDownDialog, coolDown]);

  const logOut = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('teacherId');
    navigate('/auth/login');
  }

  return (
    <Panel header="Change Password" toggleable collapsed>
        <Dialog
            visible={coolDownDialog}
            onHide={() => logOut()}
            header="Change Password"
            style={{ width: '28rem' }} 
            breakpoints={{'960px': '75vw', '640px': '95vw'}}
        >
            <div className='flex flex-column align-items-center gap-2'>
                <i className='pi pi-info-circle text-red-500' style={{fontSize: '5rem'}}></i>
                <h5 className='m-0 p-0 text-lg font-medium text-center'>Successfully Change Password, please wait <span className='font-bold'>{coolDown}</span> seconds to re-login.</h5>
            </div>
        </Dialog>
        <Form
            initialValues={initialValues}
            validate={validateResetPassword}
            onSubmit={resetPassword}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                    <Field
                        name='oldPassword'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label className='font-bold block'>Old Password</label>
                                <Password 
                                    id='oldPassword'
                                    name='oldPassword'
                                    {...input}
                                    toggleMask
                                    feedback={false}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <Field
                        name='newPassword'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label className='font-bold block'>New Password</label>
                                <Password 
                                    id='newPassword'
                                    name='newPassword'
                                    {...input}
                                    toggleMask
                                    feedback={false}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <Field
                        name='confirmPassword'
                        render={({input, meta}) => (
                            <div className='flex-auto'>
                                <label className='font-bold block'>Confirm New Password</label>
                                <Password 
                                    id='confirmPassword'
                                    name='confirmPassword'
                                    {...input}
                                    toggleMask
                                    feedback={false}
                                />
                                {getFormErrorMessage(meta)}
                            </div>
                        )}
                    />
                    <div className='flex gap-2 lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center w-full'>
                        <Button label="Update" icon="pi pi-check" type='submit' className='w-max'/>
                    </div>
                </form>
            )}
        />
    </Panel>
  )
}

export default ResertPassword
