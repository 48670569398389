import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { Link, useOutletContext } from 'react-router-dom';
import { putData } from '../../Hooks/api';
import { Toast } from 'primereact/toast';
import { Panel } from 'primereact/panel';
import ResertPassword from './ResetPassword';

const Profile = () => {
  const {teacher, setTeacher} = useOutletContext();
  const toast = useRef(null);
  const [ teacherData, setTeacherData ] = useState({});
  const [ loading, setLoading ] = useState(false);
  const [ disableButton, setDisableButton ] = useState(true);

  useEffect(() => {
    if (teacher._id) {
        teacher.tanggalLahir = new Date(teacher.tanggalLahir);
        setTeacherData(teacher);
    } 
    
  }, [teacher]);

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const validateTeacherUpdate = (data) => {
    let errors = {}

    if (!data.nama) errors.nama = "Full name is required";

    if (!data.panggilan) errors.panggilan = "Nick name is required";

    if (!data.nomor) errors.nomor = "Contact is required";

    if (!data.tempatLahir) errors.tempatLahir = "required";

    if (!data.tanggalLahir) errors.tanggalLahir = "required";

    if (!data.domisili) errors.domisili = "Domicile is required";

    if (!data.pendidikan) errors.pendidikan = "Education is required";

    if (JSON.stringify(data) !== JSON.stringify(teacher)) setDisableButton(false);
    else setDisableButton(true);
    
    return errors;
  }

  const teacherUpdate = async (data, form) => {
    setLoading(true);

    putData(`teachers/${teacherData._id}`, data)
    .then(() => {
        setTimeout(() => {
            toast.current.show({
                severity: 'success',
                summary: 'Update Success',
                detail: 'Yeay! Your profile successfully updated',
                life: 3000
            });
            setTeacher(data);
            setLoading(false);
        }, 1000);
    })
    .catch((error) => {
        if (error.response && error.response.status !== 403 && error.response.data.message !== "Unauthorized: Invalid token") {
            toast.current.show({
                severity: 'error',
                summary: 'Update Failed!',
                detail: error.response.data.message,
                life: 3000
            });
        }
        
        setLoading(false);
    })
  }

  return (
    <div className='flex flex-column gap-2'>
        <Toast ref={toast} />
        <Panel header="My Profile" toggleable>
            <Form
                initialValues={teacherData}
                validate={validateTeacherUpdate}
                onSubmit={teacherUpdate}
                render={({handleSubmit,values}) => (
                    <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                        <Field 
                            name='nama'
                            render={({input, meta}) => (
                                <div className='flex-auto'>
                                    <label htmlFor="nama" className="font-bold block mb-1">Full Name</label>
                                    <InputText 
                                        id="nama" 
                                        name='nama' 
                                        {...input} 
                                        invalid = {isFormFieldValid(meta)}
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)} 
                                </div>
                            )}
                        />
                        <Field 
                            name='panggilan'
                            render={({input, meta}) => (
                                <div className='flex-auto'>
                                    <label htmlFor="panggilan" className="font-bold block mb-1">Nick Name</label>
                                    <InputText 
                                        id="panggilan" 
                                        name='panggilan' 
                                        {...input} 
                                        invalid = {isFormFieldValid(meta)}
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)} 
                                </div>
                            )}
                        />
                        <Field 
                            name='nomor'
                            render={({input, meta}) => (
                                <div className='flex-auto'>
                                    <label htmlFor="nomor" className="font-bold block mb-1">Contact</label>
                                    <InputText 
                                        id="nomor" 
                                        name='nomor' 
                                        {...input} 
                                        invalid = {isFormFieldValid(meta)}
                                        disabled={loading}
                                        keyfilter="int"
                                    />
                                    {getFormErrorMessage(meta)} 
                                </div>
                            )}
                        />
                        <div className='flex-auto'>
                            <label htmlFor="tempatLahir" className="font-bold block mb-1">Place and Date of Birth</label>
                            <div className='flex align-items-end gap-1'>
                                <Field 
                                    name='tempatLahir'
                                    render={({input, meta}) => (
                                        <div className='w-4'>
                                            <InputText 
                                                id="tempatLahir" 
                                                name='tempatLahir' 
                                                {...input} 
                                                invalid = {isFormFieldValid(meta)}
                                                disabled={loading}
                                                placeholder='Kota / Kabupaten'
                                            />
                                            {getFormErrorMessage(meta)} 
                                        </div>
                                    )}
                                />
                                <span className='text-lg'>,</span>
                                <Field 
                                    name='tanggalLahir'
                                    render={({input, meta}) => (
                                        <div className='w-8'>
                                            <Calendar 
                                                id="tanggalLahir" 
                                                name='tanggalLahir' 
                                                {...input} 
                                                invalid = {isFormFieldValid(meta)}
                                                disabled={loading}
                                                dateFormat='dd/mm/yy'
                                                showIcon
                                            />
                                            {getFormErrorMessage(meta)} 
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        <Field 
                            name='domisili'
                            render={({input, meta}) => (
                                <div className='flex-auto'>
                                    <label htmlFor="domisili" className="font-bold block mb-1">Domicile</label>
                                    <InputText 
                                        id="domisili" 
                                        name='domisili' 
                                        {...input} 
                                        invalid = {isFormFieldValid(meta)}
                                        disabled={loading}
                                        placeholder='Kota / Kabupaten'
                                    />
                                    {getFormErrorMessage(meta)} 
                                </div>
                            )}
                        />
                        <Field 
                            name='pendidikan'
                            render={({input, meta}) => (
                                <div className='p-fluid'>
                                    <label htmlFor="pendidikan" className="font-bold block mb-1">Education</label>
                                    <InputText 
                                        id="pendidikan" 
                                        name='pendidikan' 
                                        {...input} 
                                        invalid = {isFormFieldValid(meta)}
                                        disabled={loading}
                                        placeholder='ex: D4 IT PENS'
                                    />
                                    {getFormErrorMessage(meta)} 
                                </div>
                            )}
                        />
                        <div className='flex  lg:justify-content-end md:justify-content-end sm:justify-content-end justify-content-center gap-2'>
                            <Link to='/'>
                                <Button type='button' label='Cancel' severity='danger' className='w-max' outlined loading={loading} />
                            </Link>
                            <Button type='submit' label='Update' severity='info' className='w-max' loading={loading} disabled={disableButton} autoFocus />
                        </div>    
                    </form>
                )}
            />
        </Panel>
        <ResertPassword teacher={teacher} toast={toast} />
    </div>
  )
}

export default Profile
